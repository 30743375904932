import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, message } from "antd";
import { apiGet,apiPost } from "app/services/apiServices";
import { operationTypeState, userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_CORPORATE_INFO_UPDATE, API_ENDPOINT_UPDATED_USER_INFO } from "app/scenes/Auth/auth.constant";

interface EditCorporateModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const EditCorporateModal: React.FC<EditCorporateModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  // const [companyName, setCompanyName] = useState("");
  // const [website, setWebsite] = useState("");

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue(selectedRow);
    }
  }, [selectedRow, form]);

  const onFinish = async (values: any) => {
    console.log("values are:", values);
    setIsLoading(true);
    const payload = { 
      ...selectedRow, 
      ...values, 
      operationType: "UPDATE", 
      oldcompany: selectedRow.company 
    };

    try {
      const response = await apiPost(
        `${API_ENDPOINT_CORPORATE_INFO_UPDATE}`,
        payload
      );
      // refetch the userInfo
      const userresponse = await apiPost(
        `${API_ENDPOINT_UPDATED_USER_INFO}`,
        payload);
      console.log("New User Info: ", userresponse?.data)

      if (response.status) {
        // const newWorkspace = {
        //   id: response.data.id,
        //   company: response.data.name,
        //   website: response.data.website,
        //   pan: response.pan_data,
        //   created_at: response.data.created_at,
        //   last_updated: response.data.last_updated,
        //   type: response.data.type
        // };

        setUserDetails((prev:any) => ({
          ...prev,
          ...userresponse?.data,
          currentWorkspace: userresponse?.data?.workspaceInfo
        }));

        // setUserDetails((prev: any) => ({
        //   ...prev,
        //   workspaceList: prev.workspaceList.map((workspace: any) =>
        //     workspace.id === newWorkspace.id ? { ...workspace, ...newWorkspace } : workspace
        //   )
        // }));

        message.success("Corporate details updated successfully");
        onCancel();
      } else if(!response.status) {
        if (response?.error_code === "404") {
          console.log("WORKSPACE ALREADY EXISTS")
          message.error(`An Existing workspace with ${response?.error} Name already exists, Please use a unique Workspace Name`);
        }
      } else {
        console.log("Failed Response: ", response)
      }
    } catch (error) {

      message.error("Failed to update corporate details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Edit Corporate Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={selectedRow}
      >
        <Form.Item
          label="Company Name"
          name="company"
          rules={[
            { required: true, message: "Please input the company name!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Category"
          name="type"
        >
          <Input />
        </Form.Item> */}
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditCorporateModal;
