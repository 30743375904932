import React, { useState } from "react";
import "./FAQ.scss";

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "When will we get the cargo and passenger invoices?",
    answer:
      "Cargo invoices are generated on a fortnightly basis: 1) 1st Fortnight (FN) invoices will be available by the 7th of the following month. 2) 2nd Fortnight (FN) invoices will be available by the 22nd of the following month. 3) For passenger invoices, the GST invoice will be generated within 30 to 35 days from the date of ticket issuance.",
  },
  {
    question: "Where should I provide GST details??",
    answer:
      "You should provide your GST details at the time of ticket issuance to ensure that your invoices are generated with the correct GSTN. In the Qatar Finkraft portal, GST details can be entered during the registration process or updated in your profile settings if needed. Providing accurate GST information will help in generating GST-compliant invoices, which you can later download from the portal.",
  },
  {
    question: "What is the procedure for getting invoices?",
    answer:
      "You need to register on the Qatar Finkraft portal. Once registered, you can log in using the credentials sent via email. After completing the required forms, the admin will verify your details and grant access to download invoices. If any issues arise, the admin will escalate them. You can search for your invoices using details like the ticket number or invoice number and download them directly. Ensure that you provide your GSTIN at the time of ticket issuance to keep your invoices GST-compliant.",
  },
  {
    question: "Is it compulsory to provide GST registration details?",
    answer:
      "Yes, it is compulsory to provide your GSTN (Goods and Services Tax Number) and relevant details. This ensures that all invoices related to your GSTN are correctly generated and shared with you. Providing accurate GST details also helps in proper tax compliance and record-keeping, making it easier for you to claim input tax credits if applicable.",
  },
  {
    question: "When will I receive the GST invoice for my ticket?",
    answer:
      "Cargo invoices are generated on a fortnightly basis: 1) 1st Fortnight (FN) invoices will be available by the 24th of the same month. 2) 2nd Fortnight (FN) invoices will be available by the 13th of the following month.",
  },
  {
    question: "May I provide my credentials to other users?",
    answer:
      "Yes, you can share your credentials within your organization or company. However, if you have admin access, you can add them as users so they can log in and download invoices directly.",
  },
  {
    question: "Can I download my invoice without a ticket number?",
    answer:
      "You must have either the invoice number or the ticket number for a specific ticket. Otherwise, you can find all invoices related to your GSTN in one folder.",
  },
];

const FAQComponent = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id="faq-section">
      <h2 className="faq-title">Frequently asked questions</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className="faq-question"
              onClick={() => toggleQuestion(index)}
            >
              <span>{faq.question}</span>
              <span className={`faq-icon ${openIndex === index ? "open" : ""}`}>
                {openIndex === index ? "−" : "+"}
              </span>
            </button>
            {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
