import React from "react";
import Header from "../../../shared/PageHeader";
import Footer from "../../../shared/PageFooter";
import { EyeOutlined, CheckCircleOutlined } from "@ant-design/icons";
import "./Register.scss";
import Clouds from "../../../assets/images/8918206.png";
import LogoFk from "../../../assets/images/logo FK.png";
import RegisterBg from "../../../assets/images/LoginRegisterBg.png";
import InvoiceFeatures from "../../../shared/InvoiceFeatures";
import FAQSection from "../../../shared/FAQ";
import HelpSection from "../../../shared/HelpSection";
import GoogleIcon from "../../../assets/images/Google.png";
import LinkedInIcon from "../../../assets/images/linkedin.png";
import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Select, Space, message } from "antd";
import ShieldIcon from "../../../assets/images/shield-tick-safe (1).png";
import VerificationPopup from "app/shared/VerificationPopup";
import { Button, Modal, Input } from "antd";
import type { GetProps } from "antd";
import { size } from "lodash";
import { privateDecrypt } from "crypto";
import { useNavigate } from "react-router-dom";
import { apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_AUTH_RESEND_OTP,
  API_ENDPOINT_AUTH_SIGNUP,
} from "app/scenes/Auth/auth.constant";
import { API_ENDPOINT_AUTH_SIGNUP_EMAIL_VERIFY } from "app/scenes/Auth/auth.constant";

type OTPProps = GetProps<typeof Input.OTP>;

function RegisterPage() {
  //form input state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessType, setBusinessType] = useState("CORPORATE");

  // verification and otp
  const [verifyMethod, setVerifyMethod] = useState("email");
  const [showVerification, setShowVerification] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [mobileModalOpen, setMobileModalOpen] = useState(false);
  const [otpEntered, setOtpEntered] = useState(false);
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);
  const [mobileOtpVerified, setMobileOtpVerified] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);

  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      label: "Corporate",
      key: "0",
      onClick: () => setBusinessType("Corporate"),
    },
    {
      label: "Cargo",
      key: "1",
      onClick: () => setBusinessType("Cargo"),
    },
    {
      label: "Online Agent",
      key: "2",
      onClick: () => setBusinessType("Online Agent"),
    },
    {
      label: "Travel Agent",
      key: "3",
      onClick: () => setBusinessType("Travel Agent"),
    },
  ];

  const handleOtpChange = async (text: string) => {
    if (text.length === 6) {
      // Proceed only if the OTP is 6 digits
      const payload = {
        email: email,
        otp: text,
      };

      try {
        const response = await apiPost(
          API_ENDPOINT_AUTH_SIGNUP_EMAIL_VERIFY,
          payload
        );
        console.log("OTP Verification Response:", response);

        if (response.status) {
          setEmailOtpVerified(true);
          setEmailModalOpen(false);
          setResponseStatus(true);
          message.success(
            "Login via the credentials sent to your registered Email"
          );
          navigate("/login");
        } else {
          message.error(response.message || "Invalid OTP");
        }
      } catch (error) {
        console.error("OTP Verification Error:", error);
        message.error(
          "An error occurred while verifying the OTP. Please try again."
        );
      }
    }
  };

  const verifyAndNavigate = () => {
    if (responseStatus) {
      navigate("/app");
      message.success("Login via the credentials sent on registered email ");
    } else {
      message.error("OTP verification incomplete or failed.");
    }
  };

  const registerNow = async () => {
    setLoading(true);
    const payload = {
      name: name,
      email: email,
      phone: phoneNumber,
      businessType: businessType,
    };

    const response = await apiPost(API_ENDPOINT_AUTH_SIGNUP, payload);
    if (response.status) {
      //show otp verification dialog
      setEmailModalOpen(true);
    } else {
      //show alert saying fiaile login
      console.log("Response on failed login", response);
      if (
        response.data?.code === "23505" ||
        response.data?.code === "auth0 Email Present"
      ) {
        message.error(
          "Email Already exists, Please Login or use a different email"
        );
      }
    }
    setLoading(false);

    console.log("Payload:", payload);
  };

  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleResend = async () => {
    const response = await apiPost(API_ENDPOINT_AUTH_RESEND_OTP, {
      email: email,
    });
    if (response.status) {
      message.success("We have resent the otp to your email");
    }
  };
  // for otp verification via email
  const EmailModal = () => (
    <Modal
      title="Verify by Email ID"
      style={{ fontSize: "24px", fontWeight: "600" }}
      centered
      open={emailModalOpen}
      onOk={verifyAndNavigate}
      onCancel={() => setEmailModalOpen(false)}
      okText="Verify"
      okButtonProps={{
        disabled: !otpVerified,
        style: {
          backgroundColor: "var(--primary-color)",
          color: "white",
          borderRadius: "5px",
          width: "30%",
          display: "flex",
        },
        className: "custom-ok-button",
      }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <p style={{ fontSize: "16px", fontWeight: "400" }}>
        We've sent a verification code on
      </p>
      <p style={{ fontSize: "20px", fontWeight: "600", lineHeight: "1" }}>
        {email}
      </p>
      <Input.OTP
        variant="filled"
        onChange={(value) => handleOtpChange(value)}
        // onChange={(e) => handleOtpChange(e.target.value)}
        length={6}
      />
      <p style={{ fontSize: "16px", fontWeight: "400" }}>
        Did not receive any OTP?{" "}
        <a href="#" onClick={handleResend}>
          Resend OTP
        </a>
      </p>
    </Modal>
  );

  // const MobileModal = () => (
  //   <Modal
  //     title="Verify by Mobile Number"
  //     style={{ fontSize: '24px', fontWeight: '600' }}
  //     centered
  //     open={mobileModalOpen}
  //     onOk={() => setMobileModalOpen(false)}
  //     onCancel={() => setMobileModalOpen(false)}
  //     okText="Verify"
  //     okButtonProps={{
  //       style: {
  //         backgroundColor: 'var(--primary-color)',
  //         color: 'white',
  //         borderRadius: '5px',
  //         width: '30%',
  //         display: 'flex'
  //       },
  //       className: 'custom-ok-button',
  //     }}
  //     cancelButtonProps={{ style: { display: 'none' } }}
  //   >
  //     <p style={{ fontSize: '16px', fontWeight: '400' }}>We've sent a verification code on</p>
  //     <p style={{ fontSize: '20px', fontWeight: '600', lineHeight: '1' }}>{phoneNumber}</p>
  //     <Input.OTP
  //       variant="filled"
  //       onChange={(value) => handleOtpChange(value, 'mobile')}
  //       length={6}
  //     />
  //     <p style={{ fontSize: '16px', fontWeight: '400' }}>
  //       Did not receive any OTP? <a href='#'>Resend OTP</a>
  //     </p>
  //   </Modal>
  // );

  return (
    <div className="register-page" id="registration-page">
      <div className="header">
        <Header />
      </div>
      <div className="register-hero">
        <img
          className="register-hero-bg"
          src={RegisterBg}
          alt="Person reviewing invoice"
        />
        <img className="hero-clouds" src={Clouds} alt="Clouds" />
        <div className="login-container">
          <div className="login-left">
            <div className="login-left-content">
              <img className="logo-fk" src={LogoFk} alt="" />
              <h2>Welcome To Our</h2>
              <h1>Qatar Airways GST Invoice Portal</h1>
              <p>
                Your one stop solution to download tax invoices for Corporates,
                TMCs, OTAs and Cargo
              </p>
            </div>
          </div>
          <div
            style={{
              height: "100%",
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={`login-right ${showVerification ? "blur" : ""}`}>
              <div className="login-form-container">
                <h2>Register Now</h2>
                <form
                  className="login-form"
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    registerNow(); // Call the function to log the payload
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="name">
                      Name{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <Input
                      type="text"
                      value={name}
                      defaultValue=" "
                      onChange={(e) => setName(e.target.value)}
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email{" "}
                      <span className="required" style={{ color: "red" }}>
                        *
                      </span>
                    </label>
                    <Input
                      type="email"
                      value={email}
                      defaultValue=""
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="businessType">
                      Business Type{" "}
                      <span
                        className="required"
                        style={{ color: "red", width: "100%" }}
                      >
                        *
                      </span>
                    </label>

                    <div className="select-wrapper">
                      <Select
                        defaultValue={businessType}
                        style={{ width: "100%", height: 38 }}
                        onChange={(value: any) => setBusinessType(value)}
                        options={[
                          { value: "CORPORATE", label: "CORPORATE" },
                          { value: "CARGO", label: "CARGO" },
                          { value: "TMC", label: "TMC" },
                          // { value: "PARTNER", label: "PARTNER" },
                          { value: "OTA", label: "OTA" },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="info-text">
                    <img src={ShieldIcon} alt="shield icon" />
                    <p>No spams your information is safe with us!</p>
                  </div>
                  {/* <button type="submit" className="submit-button">
                  {isLoading ? "Signing up...." : "Sign up"}
                </button> */}
                  <Button
                    type="primary"
                    onClick={registerNow}
                    loading={isLoading}
                    style={{
                      backgroundColor: "#74003B",
                      borderColor: "#74003B",
                      color: "#ffffff",
                      height: "40px",
                      width: "100%",
                      marginTop: "18px",
                    }}
                  >
                    Sign Up
                  </Button>
                </form>
                <p className="signup-prompt">
                  Already registered?{" "}
                  <a
                    href="#"
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/login")}
                  >
                    Log In
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EmailModal />

      <div className="invoice-feature-section">
        <InvoiceFeatures />
      </div>
      <div className="faq-section">
        <FAQSection />
      </div>
      <div className="help-section">
        <HelpSection />
      </div>
      <div className="footer-register">
        <Footer />
      </div>
    </div>
  );
}

export default RegisterPage;
