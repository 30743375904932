import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Checkbox, Select, message } from 'antd';
import type { FormProps } from 'antd';
import { apiPost, apiGet } from "app/services/apiServices";
import "./InviteUser.scss";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";
import { API_ENDPOINT_UPDATED_USER_INFO } from "app/scenes/Auth/auth.constant";
import {
  ArrowLeftOutlined  
} from '@ant-design/icons';

type FieldType = {
  username?: string;
  email?: string;
  mobile?: string;
  linkedin?:string;
  account_type?: string; 
};

const InviteUserModal: React.FC = ()=> {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
    const [form] = Form.useForm(); 
    const navigate = useNavigate();

  useEffect(() => {
        const areFieldsFilled =
          name.trim() !== "" && email.trim() !== "" && mobile.trim() !== ""  && linkedin.trim() !== "";
        setButtonDisabled(!areFieldsFilled);
    }, [name, email, mobile, linkedin]);

  const handleOk = async (values: FieldType)=> {
      setLoading(true);
       setConfirmLoading(true);
       const payload = {
        name: values.username,
        email: values.email,
        phone: values.mobile,
        linkedin_profile: values.linkedin,
        workspaceId: userDetails?.workspaceInfo?.id,
        type: values.account_type,
        operationType: "CREATE",
        workspaceInfo:userDetails?.workspaceInfo
       };

       try {
        const response = await apiPost(
          API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER,
          payload
        );

        const userlistpayload = userDetails?.workspaceInfo

        const userresponse = await apiPost(
          `${API_ENDPOINT_UPDATED_USER_INFO}`,
          userlistpayload);
        console.log("New User Info: ", userresponse?.data)
  
        if (response.status) {
          setUserDetails((prev:any) => ({
            ...prev,
            ...userresponse?.data,
            currentWorkspace: userresponse?.data?.workspaceInfo
          }));
          message.success("User has been added succesfully");
          navigate(-1);
          // onClose();
        } else if(!response.status) {
          if (response?.error_code === "404") {
            console.log("user is admin")
            message.error(`${response?.error}`);
            message.error('Please add a different User')
          } else if (response?.error_code === '403') {
            console.log('user already associated with workspace')
            message.error(`${response?.error}`)
            message.error('Kindly Add a different user or edit this user')
          }
        }
      } catch (error) {
        message.error("Could not add user");
      } finally {
        setConfirmLoading(false);
      }
      setLoading(false);
     };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values);
    handleOk(values); // Close modal after successful form submission
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
   const { Option } = Select;

  return (
    <>
    <ArrowLeftOutlined style={{color:'#74003B', fontSize:'26px', padding:'15px 25px 0px',cursor: "pointer",}} onClick={handleGoBack}/>
     <div className="company-details-form">
   
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="form-header">Enter Details to Add User</div>

          <Form.Item
            label="Select Account Type"
            name="account_type"
            rules={[
              {
                required: true,
                message: "Please select your account type!",
              },
            ]}
          >
            <Select placeholder="Choose account type" style={{height:'40px'}}>
              <Option value="TAX_MANAGER">Tax Manager</Option>
              <Option value="TRAVEL_CONTACT">Travel Contact</Option>
              <Option value="GENERAL_USER">General</Option>
            </Select>
          </Form.Item>

          <Form.Item<FieldType> // username
            label="Name"
            name= "username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
            type="text"
            onChange={(e) => setName(e.target.value)} 
            />
          </Form.Item>

          <Form.Item<FieldType> // Email address
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter the email address!' }]}
          >
            <Input
            type="text"
            onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>

          <Form.Item<FieldType> // Mobile Number
            label="Mobile"
            name="mobile"
            rules={[
              { 
                required: true, 
                message: 'Please enter the phone number!' 
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              }]}
          >
            <Input 
            type="text"
            onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Item>

          <Form.Item<FieldType> // Linkedin Profile
            label="LinkedIn profile link"
            name="linkedin"
            rules={[{ required: true, message: 'Please enter the linkedin profile' }]}
          >
            <Input
            type="text"
            onChange={(e) => setLinkedin(e.target.value)} />
          </Form.Item>
          
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              color="primary"
              htmlType="submit"
              loading={isLoading}
              style={{width:'100%'}}
            >
              Submit
            </Button>
          </Form.Item>
        
        </Form>
        </div>
      {/* </Modal> */}
    </>
  );
};

export default InviteUserModal;