import React, { useEffect, useState } from "react";
import "./PrimaryHeader.scss";
import { UserOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import QatarLogo from "../../assets/images/qatar-airways-1 (1) 1.png";
import UserIconColored from "../../assets/images/UserCircle (1).png";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { Link } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import SearchDropdown from "../WorkspaceSelector";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import {
  API_ENDPOINT_META_USER_INFO,
  API_ENDPOINT_UPDATED_USER_INFO,
} from "app/scenes/Auth/auth.constant";
import { apiGet, apiPost } from "app/services/apiServices";
import { Modal, message } from "antd";
import LocationIcon from "../../assets/images/EnvironmentOutlined.png";
import PhoneIcon from "../../assets/images/PhoneOutlined.png";
import MailIcon from "../../assets/images/MailOutlined.png";

import { boxPanList, selectedPanForBox } from "app/config/States/users";

const Header = () => {
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  console.log("userDetails", userDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [panBoxList, setBoxPanList] = useRecoilState<any>(boxPanList);
  const [selectedPAN, setSelectedPan] = useRecoilState<any>(selectedPanForBox);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelectWorkspce = async (workspaceInfo: any) => {
    console.log("WAKANDA info: ", workspaceInfo);
    const payload = workspaceInfo;

    const userresponse = await apiPost(
      `${API_ENDPOINT_UPDATED_USER_INFO}`,
      payload
    );

    console.log("New User Info: ", userresponse?.data);
    setUserDetails((prev: any) => ({
      ...prev,
      ...userresponse?.data,
      currentWorkspace: userresponse?.data?.workspaceInfo,
    }));
    message.success("Switched Workspaces");
    setBoxPanList(userresponse?.data?.workspaceInfo?.panlist);
    setSelectedPan(userresponse?.data?.workspaceInfo?.panlist[0]?.pan || null);
    localStorage.setItem(
      "currentBoxId",
      userresponse?.data?.workspaceInfo?.panlist[0]?.box_folder_id
    );
    localStorage.setItem(
      "currentWorkspaceId",
      userresponse?.data?.workspaceInfo?.id
    );
  };
  // console.log("workspace list:", userDetails);

  const getFilteredWorkspaceList = () => {
    return (userDetails?.workspaceList || []).filter(
      (item: any) => item.type !== "TMC"
    );
  };
  return (
    <header className="top-header">
      <div className="header-content">
        <div className="logo">
          <Link to="/app">
            <img src={QatarLogo} alt="Qatar Airways" />
          </Link>
        </div>

        <div className="header-actions">
          {/* <button className="action-btn">FAQ</button> */}

          <button className="action-btn" onClick={showModal}>
            Help
          </button>
          <Modal
            title="HELP SECTION"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <h2>Still need help?</h2>
            <p>Mail us, we will get back to you</p>
            <div className="content-wrapper">
              <div className="contact-info">
                <div className="info-item">
                  <img
                    src={LocationIcon}
                    alt="location"
                    style={{
                      width: "32px",
                      marginTop: "17px",
                      marginBottom: "-12px",
                    }}
                  />
                  <span style={{ fontSize: "15px", marginLeft: "10px" }}>
                    Bengaluru,India
                  </span>
                </div>

                <div className="info-item">
                  <img
                    src={MailIcon}
                    alt="mail"
                    style={{
                      width: "32px",
                      marginTop: "17px",
                      marginBottom: "-12px",
                    }}
                  />
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=qatarairways@finkraft.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: "15px",
                      marginLeft: "10px",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    qatarairways@finkraft.ai
                  </a>
                </div>
              </div>
            </div>
          </Modal>

          {/* <button className="action-btn profile-btn">
            <img src={UserIconColored} alt="icon" />
            <span>{userDetails?.userInfo?.name}</span>
          </button> */}

          <div className="ItemContainer" style={{ width: "fit-content" }}>
            {userDetails.workspaceInfo ? (
              <SearchDropdown
                workspaceList={getFilteredWorkspaceList()}
                handleSelectWorkspce={handleSelectWorkspce}
                userData={userDetails}
              />
            ) : null}
          </div>

          {/* <div className="scrolling-container" >
            <div className = "scrolling-content">
              {userDetails.workspaceInfo ? (
                <SearchDropdown
                  workspaceList={userDetails?.workspaceList || []}
                  handleSelectWorkspce={handleSelectWorkspce}
                  userData={userDetails}
                />
              ) : null}
              </div>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
