import React, { useState } from "react";
import { Button, Modal, Form, Input, Checkbox, Select, message } from "antd";
import type { FormProps } from "antd";
import { apiPost, apiGet } from "app/services/apiServices";
import { ButtonBaseActions } from "@mui/material";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE, API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";

type FieldType = {
  name?: string;
  website?: string;
  pan?: string;
  
};

const InviteUserModal: React.FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [name,setName] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan) || pan === "";
  };

  const handleOk = async (values: FieldType) => {

    if (values.pan && !isValidPAN(values.pan)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }
    setLoading(true);
    setConfirmLoading(true);
    console.log("values:", values);
    let payload = {
      company: values.name,
      website: values.website,
      pan: values.pan,
      type: "TMC",
      entity_type:"INTERMEDIARY",
      // agency_name: userDetails?.workspaceInfo?.id,
      agency_name: null,
      operationType: "CREATE",
      isTMC:true
    };
    console.log("payload is:", payload);

    try {
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE,
        payload
      );

      if (response.status) {
        message.success("TMC has been added succesfully");
        onClose();
      } else if (!response.status) {
        // Handle Non unique company or pan name
        if (response?.error_code === "404") {
          console.log("WORKSPACE ALREADY EXISTS")
          message.error(`Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`);
        } else if (response?.error_code === "402") {
          console.log("PAN ALREADY EXISTS")
          message.error(`PAN: ${response?.error} already exists, Please enter Unique PAN`);
        }      
      } else {
        message.error("Could not add TMC");
      }
    console.log("TMC Add triggered")
    } catch (error) {
      message.error("Could not add user");
    } finally {
      setConfirmLoading(false);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    // console.log("Success:", values);
    handleOk(values); // Close modal after successful form submission
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const { Option } = Select;

  return (
    <>
      <Modal
        title={
          <span
            style={{
              fontSize: "24px",
              fontWeight: "normal",
              marginBottom: "24px",
            }}
          >
            Add New TMC
          </span>
        }
        open={visible}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{
          style: { background: "#74003B", borderColor: "#74003B" },
        }}
        cancelButtonProps={{
          style: { color: "#74003B", border: "1px solid #74003B" },
        }}
        footer={false}
        // Footer removed for form buttons
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
         

          <Form.Item<FieldType>
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your TMC name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Website"
            name="website"
            rules={[
              {
                required: true,
                message: "Please input your website!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="PAN"
            name="pan"
           
          >
            <Input min={10} max={10} />
          </Form.Item>

          {/* <Form.Item<FieldType>
            label="LinkedIn profile link"
            name="linkedin"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              color="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InviteUserModal;
