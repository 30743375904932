import React, { useState, useEffect } from "react";
import "./Header.scss";
import QatarLogo from "../../assets/images/qatar-airways-1 (1) 1.png";
import { Alert, Button, Dropdown, Menu } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import WhatsappIcon from "../../assets/images/whatsapp-icon.png";
import { MenuOutlined } from "@ant-design/icons";
import NewsUpdate from "../NewsUpdate";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("hero-section");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Add smooth scrolling behavior to the entire document
    document.documentElement.style.scrollBehavior = "smooth";

    return () => {
      // Clean up the style when the component unmounts
      document.documentElement.style.scrollBehavior = "";
    };
  }, []);

  const isAuthRoute =
    location.pathname === "/login" || location.pathname === "/register";

  const getNavItems = () => {
    if (location.pathname === "/") {
      return [
        { id: "hero-section", label: "Home", href: "#hero-section" },
        {
          id: "about-us-section",
          label: "About Us",
          href: "#about-us-section",
        },
        {
          id: "ask-query-section",
          label: "Ask a question",
          href: "#ask-query-section",
        },
      ];
    } else if (isAuthRoute) {
      return [
        { id: "faq-section", label: "FAQ", href: "#faq-section" },
        { id: "help-section", label: "Help", href: "#help-section" },
      ]; // Empty nav for auth pages
    } else {
      return [
        { id: "faq-section", label: "FAQ", href: "#faq-section" },
        { id: "help-section", label: "Help", href: "#help-section" },
      ];
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId: any) => (e: any) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView();
      setActiveSection(sectionId);
    }
    setIsMenuOpen(false); // Close the mobile menu if it's open
  };

  const getLinkStyle = (sectionId: any) => ({
    color: activeSection === sectionId ? "var(--primary-color)" : "black",
    fontWeight: activeSection === sectionId ? "bold" : "normal",
  });

  const renderAuthButtons = () => {
    const buttonStyle = {
      transition: "all 0.3s ease",
      transform: "scale(1)",
      ":hover": {
        transform: "scale(1.05)",
      },
    };

    const primaryButtonStyle = {
      ...buttonStyle,
      backgroundColor: "var(--primary-color)",
    };

    const secondaryButtonStyle = {
      ...buttonStyle,
      color: "var(--primary-color)",
      borderColor: "var(--primary-color)",
    };

    if (location.pathname === "/login") {
      return (
        <Button
          type="primary"
          style={primaryButtonStyle}
          className="hover-scale-button"
          onClick={() => navigate("/register")}
        >
          Register Now
        </Button>
      );
    } else if (location.pathname === "/register") {
      return (
        <Button
          style={secondaryButtonStyle}
          className="hover-scale-button"
          onClick={() => navigate("/login")}
        >
          Log In
        </Button>
      );
    } else {
      return (
        <>
          <Button
            type="primary"
            style={primaryButtonStyle}
            className="hover-scale-button"
            onClick={() => navigate("/register")}
          >
            Register Now
          </Button>
          <Button
            style={secondaryButtonStyle}
            className="hover-scale-button"
            onClick={() => navigate("/login")}
          >
            Log In
          </Button>
        </>
      );
    }
  };

  const menu = (
    <Menu>
      {getNavItems().map((item) => (
        <Menu.Item key={item.id}>
          <a href={`#${item.id}`} onClick={scrollToSection(item.id)}>
            {item.label}
          </a>
        </Menu.Item>
      ))}
      {location.pathname !== "/login" && (
        <Menu.Item key="register">
          <Button
            type="primary"
            style={{ backgroundColor: "var(--primary-color)", width: "100%" }}
            onClick={() => navigate("/register")}
          >
            Register Now
          </Button>
        </Menu.Item>
      )}
      {location.pathname !== "/register" && (
        <Menu.Item key="login">
          <Button
            style={{
              color: "var(--primary-color)",
              borderColor: "var(--primary-color)",
              width: "100%",
            }}
            onClick={() => navigate("/login")}
          >
            Log In
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <header className="header">
      {/* <NewsUpdate /> */}

      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src={QatarLogo} alt="Qatar Airways" />
          </Link>
        </div>
        <nav className="nav-menu desktop-menu">
          <ul>
            {getNavItems().map((item) => (
              <li key={item.id}>
                <a
                  href={`#${item.id}`}
                  onClick={scrollToSection(item.id)}
                  style={getLinkStyle(item.id)}
                  className={activeSection === item.id ? "active" : ""}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="auth-buttons desktop-auth">
          {renderAuthButtons()}
          {/* <div className="whatsapp-icon">
            <img src={WhatsappIcon} alt="WhatsApp" />
          </div> */}
        </div>
        <div className="mobile-menu">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={isMenuOpen}
            onVisibleChange={setIsMenuOpen}
          >
            <Button icon={<MenuOutlined />} onClick={toggleMenu} />
          </Dropdown>
        </div>
      </div>
    </header>
  );
}

export default Header;
