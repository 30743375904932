import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, Select, message } from "antd";
import { apiPost } from "app/services/apiServices";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_DELINK_USER } from "app/scenes/Auth/auth.constant";

interface DelinkUserModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const DelinkUserModal: React.FC<DelinkUserModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue(selectedRow);
    }
  }, [selectedRow, form]);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    console.log("Selected Row: ", selectedRow)
    const payload = { 
        ...values, 
        user_id: selectedRow.user_id, 
        workspace_id: userDetails?.workspaceInfo?.id 
    };

    try {
      const response = await apiPost(
        `${API_ENDPOINT_DELINK_USER}/${payload.user_id}`,
        payload
      );

      if (response.status) {
        message.success(response?.message)
        setUserDetails((prev:any) => ({
            ...prev,
            genericUsers: prev.genericUsers?.filter((genUser:any) => 
                genUser.user_id !== selectedRow.user_id),
            tax_managers: prev.tax_managers?.filter((TM:any) => 
                TM.user_id !== selectedRow.user_id),
            travel_contacts: prev.travel_contacts?.filter((TC:any) => 
                TC.user_id !== selectedRow.user_id)
        }));
        setIsLoading(false)
        onCancel();
      } else {
        message.error(response.message)
        setIsLoading(false)
      }
    } catch (error) {
      message.error("Failed to update user details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Delink User Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        // initialValues={selectedRow}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Re-enter email to confirm delinking."
          name="verify_email"
          rules={[
            { required: true, message: "Please input the correct email!" },
            ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The emails do not match'));
                },
              }),
          ]}
        >
          <Input />
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default DelinkUserModal;
