import React, {useState, useEffect, useCallback} from 'react';
import { Button, Form, Input, message } from 'antd';
import { apiPost, apiGet } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo, operationTypeState, hardRefresh } from "app/config/States/users";
import ShieldIcon from '../../../../assets/images/shield-tick-safe (1).png';
import "./TaxManager.scss";

interface StepTwoProps {
  onNext: () => void;
  onPrev: () => void;
}

const StepTwo: React.FC<StepTwoProps> = ({ onNext, onPrev}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [prevEmail, setPrevEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [operationType, setOperationType] = useRecoilState(operationTypeState);
  const [form] = Form.useForm();
  const [isButtonDisabled, setButtonDisabled] = useState(true);
   
  console.log("userdetailstax:", userDetails)
  useEffect(() => {
      // Prefill form fields with data from Recoil state
      
      setName(userDetails?.tax_managers?.[0]?.name || '');
      const initialEmail = userDetails?.tax_managers?.[0]?.email || '';
      setEmail(userDetails?.tax_managers?.[0]?.email || '');
      setMobile(userDetails?.tax_managers?.[0]?.phone || '');
      setLinkedinProfile(userDetails?.tax_managers?.[0]?.linkedin_profile || '');

      if (operationType === "CREATE") {
        setPrevEmail(initialEmail);
      }
  
      // Prevent email modification during "UPDATE"
      // if (operationType === "UPDATE") {
      //   setEmail(initialEmail);
      // } else {
      //   setEmail(initialEmail); // For "CREATE", set the email normally
      // }
    }, [userDetails,  operationType, prevEmail]);

  useEffect(() => {
        const areFieldsFilled = name.trim() !== '' && email.trim() !== '' && mobile.trim() !== '' ;
        setButtonDisabled(!areFieldsFilled);
        const prevEmail = email.trim();
      }, [name, email, mobile, linkedinProfile]);
    

  console.log("prevEmail:", prevEmail);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let trimmedName = name.trim();
    let trimmedEmail = email.toLowerCase().trim();
    let trimmedMobile = mobile.trim();
    let trimmedLinkedinProfile = linkedinProfile.trim();

    const operationType = userDetails?.tax_managers?.length > 0 ? "UPDATE" : "CREATE";

    let payload = {
      oldemail: prevEmail.toLowerCase(),
      name: trimmedName,
      email: trimmedEmail,
      phone: trimmedMobile,
      linkedin_profile: trimmedLinkedinProfile,
      workspaceId: userDetails?.workspaceInfo?.id,
      type: "TAX_MANAGER",
      operationType: operationType,
      workspaceInfo:userDetails?.workspaceInfo
    };
    console.log("payload is:", payload);

    const response = await apiPost(
      API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER,
      payload
    );

    if (response.status) {
      const newTaxManager = {
        user_id:response.userid,
        name: trimmedName,
        email: trimmedEmail, // Ensure updated email is saved
        phone: trimmedMobile,
        linkedin_profile: trimmedLinkedinProfile,
        account_type: "TAX_MANAGER",
        workspace_role: "TAX_MANAGER"
      }
      // setName("");
      // setEmail("");
      // setMobile("");
      // setLinkedinProfile("");

      setUserDetails((prev: any) => ({
        ...prev,
        tax_managers:[
          newTaxManager,
          ...(prev.tax_managers?.filter((tm:any)=> tm.user_id !== newTaxManager.user_id) || [])
          ],
        travel_contacts:[
          ...(prev.travel_contacts?.filter((tc:any)=> tc.user_id !== newTaxManager.user_id) || [])
          ]
        })
      );
      form.validateFields()
      .then(() => {
        onNext(); // Trigger the next step
        message.success("Tax Manager Details updated");
      })
      .catch((info) => {
        console.error('Validation Failed:', info);
      });
      } else if (response?.error_code === "404") {
        message.error(`Email: ${response?.error} is already an Admin for this workspace`)
      } else if (response?.error_code === "403") {
        message.error(`${response?.error}`)
      } else {
        message.error("Could not update Tax Manager details");
      }
      setLoading(false);
      // Here you can handle the form submission, e.g., send the data to a backend
  };

  const handleBack = () => {
    onPrev();
  }

  return (
    <div className='tax-manager-form'>
    <Form form={form} layout="vertical"
    fields={[
      {
        name:'Name',
        value:name
      },
      {
        name:'Email',
        value: email
      },
      {
        name:'Mobile',
        value: mobile
      },
      {
        name:'LinkedIn profile link',
        value: linkedinProfile
      }
    ]}>
      <div className="form-header">Tax Manager</div>
      <Form.Item
        label="Name"
        name="Name"
        rules={[
          { required: true, message: 'Please input your name!' },
        ]}
      >
        <Input 
          placeholder="Enter your name"
          type="text"
          defaultValue={name}
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ borderColor: "#C3C4CD" }} />
      </Form.Item>

      <Form.Item
        label="Email"
        name="Email"
        rules={[
          { required: true, message: 'Please input your email!' },
          // { type: 'email', message: 'Please enter a valid email!' },
        ]}
      >
        <Input 
          type="text"
          defaultValue={email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email address"
          style={{ borderColor: "#C3C4CD" }} />
      </Form.Item>

      <Form.Item
        label="Mobile"
        name="Mobile"
        rules={[
          { required: true, message: 'Please input your mobile number!' },
        ]}
      >
        <Input 
          type="text"
          defaultValue={mobile}
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          placeholder="Enter Mobile Number"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <Form.Item
        label="LinkedIn profile link"
        name="LinkedIn profile link"
        rules={[
          { required: false, message: 'Please input your LinkedIn profile link!' },
        ]}
      >
        <Input 
          type="text"
          defaultValue={linkedinProfile}
          value={linkedinProfile}
          onChange={(e) => setLinkedinProfile(e.target.value)}
          placeholder="LinkedIn profile link"
          style={{ borderColor: "#C3C4CD" }}
         />
      </Form.Item>

      <div className="info-text">
          <img src={ShieldIcon} alt="shield icon" />
          <p>No spams your information is safe with us!</p>
        </div>
      <div className='btn-container'>
            <Button type="primary" onClick={handleBack} style={{width:'48%', background:'#ffffff', border:'1px solid #74003B', color:'#74003B', height:'40px'}}>
              Back
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={isButtonDisabled}  loading={isLoading} style={{width:'48%',  backgroundColor: isButtonDisabled ? '#A8A8A8' : '#74003B',  borderColor: isButtonDisabled ? '#A8A8A8' : '#74003B', color:'#ffffff', height:'40px'}}>
              Next
            </Button>
      </div>
      
    </Form>
    </div>
  );
};

export default StepTwo;
