import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, Select, message } from "antd";
import { apiPost } from "app/services/apiServices";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_USER_INFO_UPDATE } from "app/scenes/Auth/auth.constant";

interface EditUserModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue(selectedRow);
    }
  }, [selectedRow, form]);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    console.log("Selected Row: ", selectedRow)
    const payload = { ...values, user_id: selectedRow.user_id };

    try {
      const response = await apiPost(
        `${API_ENDPOINT_USER_INFO_UPDATE}/${payload.user_id}`,
        payload
      );

      if (response.status) {
        message.success("User details updated successfully");
        setUserDetails((prev:any)=>({
          ...prev,
          genericUsers: prev.genericUsers?.map((genUser:any) => 
            genUser?.user_id === response.data?.user_id
            ? {...genUser, ...response?.data} 
            : genUser ),
          tax_managers: prev.tax_managers?.map((tax_managers:any) => 
            tax_managers?.user_id === response.data?.user_id
            ? {...tax_managers, ...response?.data} 
            : tax_managers ),
          travel_contacts: prev.travel_contacts?.map((travel_contacts:any) => 
            travel_contacts?.user_id === response.data?.user_id
            ? {...travel_contacts, ...response?.data} 
            : travel_contacts )
        }));
        onCancel();
      }
    } catch (error) {
      message.error("Failed to update user details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Edit User Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        // initialValues={selectedRow}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Mobile"
          name="phone"
          rules={[
            { required: true, message: "Please input the mobile number!" },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Category"
          name="account_type"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <Select
            placeholder="Select a category"
            options={[
              { value: 'TAX_MANAGER', label: 'TAX_MANAGER' },
              { value: 'TRAVEL_CONTACT', label: 'TRAVEL_CONTACT' },
            ]}
          />
        </Form.Item> */}
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
