export const API_ENDPOINT_AUTH_SIGNUP = `${process.env.REACT_APP_API_URL}/qatar/auth/signup`;
export const API_ENDPOINT_AUTH_SIGNUP_EMAIL_VERIFY = `${process.env.REACT_APP_API_URL}/qatar/auth/email/verify`;
export const API_ENDPOINT_AUTH_SIGNIN = `${process.env.REACT_APP_API_URL}/qatar/auth/login`;
export const API_ENDPOINT_META_USER_INFO = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/user/info`;
export const API_ENDPOINT_UPDATED_USER_INFO = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/user/updatedinfo`;
export const API_ENDPOINT_USER_INFO_UPDATE = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/user/info`;
export const API_ENDPOINT_CORPORATE_INFO_UPDATE = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/corporateinfo`;
export const API_ENDPOINT_PAN_INFO_UPDATE = `${process.env.REACT_APP_API_URL}/qatar/pan/`;
export const API_ENDPOINT_FETCH_USER_LIST = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/fetchuserlist/:workspaceId`;
export const API_ENDPOINT_AUTH_RESEND_OTP = `${process.env.REACT_APP_API_URL}/qatar/auth/signup/resend`;
export const API_ENDPOINT_AUTH_SIGNIN_OTP = `${process.env.REACT_APP_API_URL}/qatar/auth/loginotp`;
export const API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP = `${process.env.REACT_APP_API_URL}/qatar/auth/loginotpverify`;
export const API_ENDPOINT_DELINK_USER = `${process.env.REACT_APP_API_URL}/qatar/delink_user`;