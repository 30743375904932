import React, { useState } from 'react';
import { Input } from 'antd';
import './QuerySection.scss';
import { Button , Form, message} from 'antd';
import { apiPost } from 'app/services/apiServices';
import { API_ENDPOINT_HELP_QUERY_SUBMIT } from 'app/shared/MasterLayout/masterlayout.constant'; 

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    query: ''
  });
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [query, setQuery] = useState("");

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  // };

  const handleSubmit = async () => {
    try {
      // Validate form fields
      const values = await form.validateFields();
      
      // Prepare payload
      const payload = {
        name: values.name,
        email: values.email,
        mobile: values.phone,
        query: values.query || '' // Make query optional
      };

      // Make API call
      const response = await apiPost(API_ENDPOINT_HELP_QUERY_SUBMIT, payload);

      // Check API response
      if (response.status === true) {
        // Success scenario
        console.log('Callback request successful');
        message.success('Callback request submitted successfully');
        
        // Close modal and reset form
        form.resetFields();
      } else {
        // Error scenario
        console.log('Callback request unsuccessful');
        message.error(response.message || 'Failed to submit callback request');
      }
    } catch (errorInfo) {
      // Validation failed
      console.log('Validation Failed:', errorInfo);
      message.error('Please fill in all required fields');
    } finally {
      // Always reset loading state
      console.log('Success');
    }
  };

  return (
    <div className="contact-form-container" id='ask-query-section'>
      <h1>Got any query?</h1>
      <p>Help us to reach out to you</p>
      <h2>Contact us</h2>
      <Form 
          form={form}
          layout="vertical"
          name="callback_request_form"
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: 'Please enter your full name',
              },
            ]}
          >
            <Input 
             type="text"
             defaultValue=" "
             value={name}
             onChange={(e) => setName(e.target.value)}
             placeholder="Enter your full name" 
             style={{border: "0.5px solid #C3C4CD", borderRadius:"7px", width:'50%' }}
             />

          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter your email address',
              },
            ]}
          >
            <Input 
             type="text"
             defaultValue=" "
             value={email}
             onChange={(e) => setEmail(e.target.value)}
             style={{border: "0.5px solid #C3C4CD", borderRadius:"7px", width:'50%'}}
             placeholder="Enter your email address" />
             
          </Form.Item>
          
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                pattern: /^[0-9]{10}$/,
                message: 'Please enter a valid 10-digit phone number',
              },
            ]}
          >
            <Input 
             type="text"
             defaultValue=" "
             style={{ border: "0.5px solid #C3C4CD", borderRadius:"7px", width:'50%'}}
             value={mobile}
             onChange={(e) => setMobile(e.target.value)}
             placeholder="Enter 10-digit phone number" 
             addonBefore="+91"
             
            />
          </Form.Item>
          
          <Form.Item
            name="query"
            label="Write your query here (Optional)"
          >
            <Input.TextArea 
              rows={3} 
              defaultValue=" "
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Briefly describe your query or concern" 
              style={{border: "0.5px solid #C3C4CD", borderRadius:"7px", width:'50%' }}
            />
          </Form.Item>
          <div className='submit-btn-container'>
        <Button className='submit-button'  style={{borderColor:'var(--primary-color)', color:'#74003B' , width: '14%', fontWeight:'600' }} onClick={handleSubmit}>Let's Connect</Button>
        </div>
        </Form>
    </div>

  );
};

export default ContactForm;