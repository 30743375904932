import React from "react";
import "./AboutUs.scss";
import AboutUsImg from "../../../assets/images/about-us-img.png";
import { Button } from "antd";
const AboutUs = () => {
  return (
    <div className="about-us-container" id="about-us-section">
      <div className="about-us-content">
        <h1>About Us</h1>
        <h2>GST Invoices Portal</h2>
        <p>
          Qatar Airways has partnered with Finkraft to offer an advanced automated
          solution for efficiently retrieving GST invoices. With the launch of a
          dedicated Qatar Airways portal, customers can seamlessly register and gain
          instant access to their invoices with ease.
        </p>
        {/* <Button
          className="know-more-btn"
          style={{
            borderColor: "#46003B",
            color: "#434343",
            fontSize: "17px",
            marginTop: "10px",
          }}
        >
          Know more
        </Button> */}
      </div>
      <div className="about-us-image">
        <img src={AboutUsImg} alt="Person reviewing invoice" />
      </div>
    </div>
  );
};

export default AboutUs;
