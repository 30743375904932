import React, { useState } from "react";
import { Button, Modal, Form, Input, Checkbox, message } from "antd";
import type { FormProps } from "antd";
import { apiPost, apiGet } from "app/services/apiServices";
import { API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

type FieldType = {
  company?: string;
  website?: string;
  pan?: string;
};

const AddPanModal: React.FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async (values: FieldType) => {
    setLoading(true);
    const payload = {
      pan: values.pan,
      workspaceId: userDetails?.workspaceInfo?.id,
    };
    try {
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE,
        payload
      );

      if (response.status) {
        message.success("Pan added succesfully");
        onClose();
      }
    } catch (error) {
      message.error("Could not add pan");

      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    handleOk(values); // Close modal after successful form submission
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        title={
          <span
            style={{
              fontSize: "24px",
              fontWeight: "normal",
              marginBottom: "24px",
            }}
          >
            Add Pan
          </span>
        }
        open={visible}
        // onOk={handleOk}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{
          style: { background: "#74003B", borderColor: "#74003B" },
        }}
        cancelButtonProps={{
          style: { color: "#74003B", border: "1px solid #74003B" },
        }}
        footer={false}
        // Footer removed for form buttons
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {/* <Form.Item<FieldType>
            label="Company Name"
            name= "company"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item> */}

          {/* <Form.Item<FieldType>
            label="Company website"
            name="website"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item<FieldType>
            label="PAN Number"
            name="pan"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              color="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddPanModal;
