// EditPANModal.tsx
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, Select, message } from "antd";
import { apiPost, apiPut } from "app/services/apiServices";
import { userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_EDIT_PAN_IN_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";

interface EditPANModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const EditPANModal: React.FC<EditPANModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pan, setPan] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedEntity, setSelectedEntity] = useState("");

  useEffect(() => {
    if (selectedRow) {
      setPan(selectedRow.pan || ""); // Set pan from selectedRow
      form.setFieldsValue(selectedRow); // Pre-fill form values
      setSelectedEntity(userDetails?.workspaceInfo?.id);
    }
  }, [selectedRow, form]);

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const onFinish = async (values: any) => {
    if (!isValidPAN(pan)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }

    setIsLoading(true);

    const fixedpan = pan.toUpperCase();

    console.log("Selected Entity in dropdown: ", selectedEntity);
    // const updatedEntityName = selectedEntity ? selectedEntity.entity_name : selectedRow.entity_name;
    // const updatedEntityId = selectedEntity;


    const payload = {
    ...values,
    email:userDetails?.userInfo?.email,
    userId:userDetails?.userInfo?.id,
    pan:values?.pan.toUpperCase(),
    oldPan: selectedRow.pan,
    oldEntityId: userDetails?.workspaceInfo?.id,
    // entity_name: updatedEntityName,
    workspaceId: selectedEntity,
    };

    // console.log("Edit PAN Payload: ", payload)

    try {
      // Add your PAN update API endpoint here
      const response = await apiPost(
        API_ENDPOINT_EDIT_PAN_IN_WORKSPACE,
        payload
      );

      if (response.status) {
        message.success("PAN details updated successfully");
        console.log("Updated Workspace Info ", response)
        setUserDetails((prev :any)=> ({
          ...prev,
          workspaceInfo:response.data
        }))

        onCancel();
      } else if(!response.status) {
        if (response?.error_code === "404") {
          console.log("PAN ALREADY EXISTS")
          message.error(`An Existing workspace with ${response?.error} PAN Number already exists, Please add a unique pan`);
        }
      }
    } catch (error) {
      console.log("Pan Update Error due to: ", error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    console.log("Updated User Details are: ", userDetails)
  },[userDetails])

  return (
    <Modal
      title="Edit PAN Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={selectedRow}
      >
        <Form.Item 
        label="PAN" 
        name="pan"
        rules={[
          { 
            required: true, 
            message: 'Please input your PAN Number!' 
          },
          {
            pattern: /^(?:[0-9a-zA-Z]{10}|[0-9a-zA-Z]{13})$/,
            message: 'Please Enter a valid PAN Number'
          }
        ]}>
          <Input value={pan} onChange={(e: any) => setPan(e.target.value)} />
        </Form.Item>
        <Form.Item label="Entity Name" name="entity_name">
          <Select
            value = {selectedEntity}
            placeholder="Select an Entity"
            optionFilterProp="label"
            onChange={(value) => setSelectedEntity(value)} 
            options={Array.from(
              new Map(
                (userDetails?.workspaceList || []).map((workspace: any) => [workspace.company, workspace])
              ).values()
            ).map((entity: any) => ({
              value: entity.id,
              label: entity.company,
            }))}
          />
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditPANModal;
