import React from "react";
import {
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import "./Footer.scss";
import FinkraftLogo from "../../assets/images/FinLogo.png";

function PageFooter() {
  const handleAboutClick = (event: any) => {
    event.preventDefault(); // Prevent default behavior (adding #id to the route)
    const aboutSection = document.getElementById("about-us-section");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        <hr className="footer-divider" />
        <div className="footer-bottom">
          <p className="powered-by">
            powered by{" "}
            <img
              src={FinkraftLogo}
              alt="Finkraft logo"
              className="company-name"
            />
          </p>
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;
