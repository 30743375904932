import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import CompanyDetails from "../Onboarding/Components/CompanyDetails";
import TaxManager from "../Onboarding/Components/TaxManager";
import TravelContact from "./Components/TravelContact";
import SuccessMessage from "./Components/SuccessfulOnboarding";
import "./Onboarding.scss";
import { userInfo, operationTypeState } from "app/config/States/users";
import { useRecoilState } from "recoil";
import Loader from "app/shared/Loader";

const StepFour: React.FC = () => {
  return <p>All steps completed! Thank you.</p>;
};

const App: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [operationType, setOperationType] = useRecoilState(operationTypeState);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const isPendingVerification =
      userDetails?.userInfo?.status === "PENDING" &&
      Array.isArray(userDetails?.tax_managers) &&
      userDetails.tax_managers.length > 0 &&
      Array.isArray(userDetails?.travel_contacts) &&
      userDetails.travel_contacts.length > 0 &&
      Array.isArray(userDetails?.workspaceList) &&
      userDetails.workspaceList.length > 0;

    if (isPendingVerification && operationType !== "UPDATE") {
      setCurrent(3);
    }
    setLoading(false);
  }, [userDetails]);

  const handleShowPreview = () => {
    setOperationType("UPDATE");
  };
  console.log("operartion type is:", operationType);

  const steps = [
    {
      title: "Company Details",
      content: <CompanyDetails onNext={() => setCurrent((prev) => prev + 1)} />,
    },
    {
      title: "Tax Manager Details",
      content: (
        <TaxManager
          onNext={() => setCurrent((prev) => prev + 1)}
          onPrev={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
    {
      title: "Travel Contact Details",
      content: (
        <TravelContact
          onNext={() => setCurrent((prev) => prev + 1)}
          onPrev={() => setCurrent((prev) => prev - 1)}
        />
      ),
    },
    {
      title: "Verify & View Invoices",
      content: (
        <SuccessMessage
          onPreview={() => {
            handleShowPreview();
            setCurrent(0);
          }}
          handleShowPreview={handleShowPreview}
        />
      ),
    },
  ];

  const getStepStatusText = (stepIndex: number) => {
    if (stepIndex < current) {
      return { text: "Completed", color: "green" };
    } else if (stepIndex === current) {
      return { text: "In Progress", color: "#74003B" };
    }
    return { text: "Pending", color: "gray" };
  };

  return (
    <div className="onboarding">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Steps size="small" current={current}>
            {steps.map((step, index) => {
              const { text, color } = getStepStatusText(index);
              return (
                <Steps.Step
                  key={index}
                  title={
                    <div>
                      {step.title}
                      <p style={{ fontSize: "12px", margin: 0, color }}>
                        {text}
                      </p>
                    </div>
                  }
                />
              );
            })}
          </Steps>
          <div style={{ marginTop: 24 }}>{steps[current]?.content}</div>
        </>
      )}
    </div>
  );
};

export default App;
