import React, { useState } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash.debounce";
import { API_ENDPOINT_ONBOARDING_SEARCH_ENTITY } from "../MasterLayout/masterlayout.constant";

const { Option } = Select;

const EntitySearch = (props) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchOptions = debounce((searchText) => {
    setFetching(true);
    // Simulate an API call
    fetch(API_ENDPOINT_ONBOARDING_SEARCH_ENTITY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: searchText, // Sending search text in the body
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const formattedOptions = (data.data || []).map((user) => ({
          label: user.name,
          value: user.id,
        }));
        setOptions(formattedOptions);
        setFetching(false);
      })
      .catch(() => {
        setOptions([]);
        setFetching(false);
      });
  }, 300);

  const handleSearch = (value) => {
    if (value) {
      fetchOptions(value);
    } else {
      setOptions([]);
    }
  };

  const handleChange = (value) => {
    console.log("Selected:", value);
    props.onSelect && props.onSelect(value);
  };

  return (
    <Select
      showSearch
      placeholder="Search for a entity"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      style={{ width: "100%" }}
      allowClear
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default EntitySearch;
