import React, { useEffect, useRef, useState } from "react";
import { Card, Modal, Popconfirm } from "antd";
import type { PopconfirmProps } from "antd";
import CustomAgGrid, { ColumnDefinition } from "../../shared/AgGrid";
import { Input, Dropdown, Space, MenuProps, Button, message, Tag } from "antd";
import { CheckOutlined, SendOutlined } from "@ant-design/icons";
import "./MailStatus.scss";
import moment from "moment";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_MAIL_STATUS_REQUEST,
  API_ENDPOINT_MAIL_STATUS_CARD_DATA,
  API_ENDPOINT_NOTIFY_RESOLVE_MAIL_STATUS,
} from "../../shared/MasterLayout/masterlayout.constant";
import TextArea from "antd/es/input/TextArea";

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: number;
}

interface BusinessData {
  user_name: string;
  user_email: string;
  mobile: string;
  mailSent: string;
  clientResponseDate: string;
}

const StatCard: React.FC<StatCardProps> = ({ icon, label, value }) => {
  return (
    <Card className="stat-card">
      <div className="stat-inner">
        <div className={`stat-icon `}>{icon}</div>
        <div className="stat-content">
          <h3 className="stat-label">{label}</h3>
          <p className="stat-value">{value}</p>
        </div>
      </div>
    </Card>
  );
};

const MailStatus: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [cardData, setCardData] = useState({
    total_sent: 0,
    pending_response: 0,
    pending_admin_actions: 0,
    total_completed: 0,
  });
  const [selectedRow, setSelectedrow] = useState<any>(null);

  const [isLoading, setLoading] = useState(false);
  //  const [emailSent, setEmailSent] = useState('');
  //  const [pendingResponse, setPendingResponse] = useState('');
  //  const [pendingAction, setPendingAction] = useState('');
  //  const [completedCase, setCompleted] = useState('');
  const [messageState, setMessageState] = useState("");
  const [isResolved, setIsResolved] = useState(false);
  const [notify, setNotify] = useState(false);
  const [showModal, setShowModal] = useState(false);

  //  const [currentStatus, setCurrentStatus] = useState(params.data.status); // Initialize with current row status
  useEffect(() => {
    fetchTableData();
    fetchCardData();
  }, []);

  const fetchTableData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_MAIL_STATUS_REQUEST);
    if (response.status) {
      setTableData(response.data);
    }
    setLoading(false);
  };

  const updateEscalationStatus = async (
    type: string,
    selectedData = selectedRow
  ) => {
    const payload = {
      email: selectedData.user_email,
      message: messageState,
      name: selectedData.user_name,
      type: type,
      userId: selectedData.user_id,
      pan: selectedData.pan,
    };
    try {
      const response = await apiPut(
        `${API_ENDPOINT_NOTIFY_RESOLVE_MAIL_STATUS}/${selectedData.escalation_id}`,
        payload
      );
      if (response.status) {
        message.success(
          type === "NOTIFY"
            ? "User has been notified successfully"
            : "Escalation status updated successfully."
        );
        fetchTableData();
        return response.data.status;
      } else {
        message.error("Failed to update escalation status.");
      }
    } catch (error) {
      console.error("Error updating escalation status:", error);
    }
    setShowModal(false);
    setMessageState("");
  };

  const fetchCardData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_MAIL_STATUS_CARD_DATA);
    if (response.status) {
      setCardData(response.data);
    }
    setLoading(false);
  };

  const confirm: PopconfirmProps["onConfirm"] = (e) => {
    console.log(e);
    message.success("Click on Yes");
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  console.log("selectedRow", selectedRow);

  //  const rowData: BusinessData[] = [

  //    {
  //     user_name:'ERTG34567',
  //     user_email:'tabrez',
  //     mobile:'34',
  //     mailSent: '15',
  //     clientResponseDate: '03/11/2024',

  //    },
  //    {
  //     user_name:'ERTG34567',
  //     user_email:'tabrez',
  //     mobile:'34',
  //     mailSent: '15',
  //     clientResponseDate: '03/11/2024',

  //    },
  //    {
  //     user_name:'ERTG34567',
  //     user_email:'tabrez',
  //     mobile:'34',
  //     mailSent: '15',
  //     clientResponseDate: '03/11/2024',

  //    },
  //    {
  //     user_name:'ERTG34567',
  //     user_email:'tabrez',
  //     mobile:'34',
  //     mailSent: '15',
  //     clientResponseDate: '03/11/2024',

  //    },

  // ];

  const ActionCellRenderer = ({ params }: { params: any }) => {
    const [currentStatus, setCurrentStatus] = useState(params.data.status);
    const [showModal, setShowModal] = useState(false);

    // if (currentStatus === "RESOLVED") {
    //   return <Tag color="green"> ✅ Resolved</Tag>;
    // }
    // if (currentStatus === "EMAIL_RESEND") {
    //   return (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <Tag color="yellow" icon={<CheckOutlined />}>
    //         Reminder Sent
    //       </Tag>
    //       <Tag
    //         color="blue"
    //         icon={<SendOutlined />}
    //         onClick={() => handleUpdateStatus(true)}
    //       >
    //         Notify
    //       </Tag>
    //     </div>
    //   );
    // }

    return (
      <div style={{ display: "flex", gap: "8px" }}>
        {params.data.status === "RESOLVED" ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            icon={<CheckOutlined />}
          >
            Resolved
          </Tag>
        ) : (
          <div>
            <Popconfirm
              title="Resolve the task"
              description="Are you sure to resolve this task?"
              onConfirm={() => handleUpdateStatus(false)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                style: { backgroundColor: "#74003B", borderColor: "#74003B" },
              }}
            >
              <Tag color="green" style={{ cursor: "pointer" }}>
                Resolve
              </Tag>
            </Popconfirm>

            <Tag
              color="blue"
              icon={<SendOutlined />}
              onClick={() => {
                setShowModal(true);
                setSelectedrow(params.data);
              }}
              style={{ cursor: "pointer" }}
            >
              Notify
            </Tag>
          </div>
        )}

        {/* <Tag
          color="red"
          style={{ cursor: "pointer" }}
          onClick={() => handleUpdateStatus(true)}
        >
          Notify
        </Tag> */}
      </div>
    );
  };

  const handleUpdateStatus = async (isNotify = true, selectedData = null) => {
    const updatedStatus = await updateEscalationStatus(
      isNotify ? "NOTIFY" : "RESOLVED",
      selectedData
    ); // Call the API and get updated status
    if (updatedStatus) {
      console.log(updatedStatus, "this is the updated status");
      fetchTableData();
    }
  };

  const columnDefs: ColumnDefinition[] = [
    {
      field: "user_name",
      headerName: "Name",
      width: 200,
      sortable: true,
      filter: true,
    },
    {
      field: "user_email",
      headerName: "Email",
      width: 200,
      sortable: true,
      filter: true,
    },
    { field: "mobile", headerName: "Mobile", sortable: true },
    { field: "escalation_type", headerName: "Type", enableRowGroup: true },
    {
      field: "last_email_sent",
      headerName: "Mail Sent (date)",
      sortable: true,
      width: 200,
      //@ts-ignore
      cellRenderer: (params: any) =>
        moment(params.data?.last_email_sent || "").format("DD MMM YYYY"),
    },
    // {
    //   field: "responded_at",
    //   headerName: "Client Response (date)",
    //   sortable: true,
    // },
    // { field: "travelContact", headerName: "Client Response (Action)", sortable: true },
    // {
    //   headerName: "Client Response (Action)",
    //   field: "responded",
    //   cellRenderer: (params: any) => {
    //     return params.data.responded === true ? (
    //       <Tag color="green">Received</Tag>
    //     ) : params.data.responded === false ? (
    //       <Tag color="red">Not Received</Tag>
    //     ) : (
    //       <div style={{ display: "flex", gap: "8px" }}>
    //         <Button
    //           type="primary"
    //           style={{
    //             backgroundColor: "#F1FCF3",
    //             borderColor: "#329900",
    //             color: "#329900",
    //             fontSize: "12px",
    //             width: "83%",
    //             marginTop: "2px",
    //             marginLeft: "15%",
    //           }}
    //           // onClick={() =>
    //           //   handleManageRequest(params.data.invoice_id, "ACCEPTED")
    //           // }
    //         >
    //           ✓ Positive
    //         </Button>
    //         {/* <Button
    //           danger
    //           style={{
    //             backgroundColor: "#fecece",
    //             borderColor: "#ff4d4f",
    //             fontSize: "12px",
    //             width: "35%",
    //             marginTop: "2px",
    //           }}
    //           // onClick={() =>
    //           //   handleManageRequest(params.data.invoice_id, "REJECTED")
    //           // }
    //         >
    //           ☓ Negative
    //         </Button> */}
    //       </div>
    //     );
    //   },
    // },
    {
      headerName: "Mail Status",
      field: "",
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag color="yellow" icon={<CheckOutlined />}>
            Reminder Sent
          </Tag>
        );
      },
    },

    {
      headerName: "Action",
      field: "responded",
      width: 200,
      cellRenderer: (params: any) => (
        <div style={{ display: "flex", gap: "8px" }}>
          {params.data.status === "RESOLVED" ? (
            <Tag
              color="green"
              style={{ cursor: "pointer" }}
              icon={<CheckOutlined />}
            >
              Resolved
            </Tag>
          ) : (
            <div>
              <Popconfirm
                title="Resolve the task"
                description="Are you sure to resolve this task?"
                onConfirm={() => {
                  handleUpdateStatus(false, params.data);
                }}
                okText="Yes"
                cancelText="No"
                okButtonProps={{
                  style: { backgroundColor: "#74003B", borderColor: "#74003B" },
                }}
              >
                <Tag color="green" style={{ cursor: "pointer" }}>
                  Resolve
                </Tag>
              </Popconfirm>

              <Tag
                color="blue"
                icon={<SendOutlined />}
                onClick={() => {
                  setShowModal(true);
                  setSelectedrow(params.data);
                }}
                style={{ cursor: "pointer" }}
              >
                Notify
              </Tag>
            </div>
          )}
        </div>
      ),
      //    {

      //   const handleResolve = async () => {
      //     setMessageState(false);
      //     const response = await updateEscalationStatus(params.data);
      //     if (response.status === "RESOLVED") {
      //       return <Tag color="gray">Resolved</Tag>;
      //     }
      //   };

      //   return (
      //     <div style={{ display: "flex", gap: "8px" }}>
      //       <Popconfirm
      //           title="Resolve the task"
      //           description="Are you sure to resolve this task?"
      //           onConfirm={handleResolve}
      //           onCancel={cancel}
      //           okText="Yes"
      //           cancelText="No"
      //           okButtonProps={{ style: { backgroundColor: '#74003B', borderColor: '#74003B' } }}
      //       >

      //         <Tag color="green" style={{cursor:'pointer'}}>Resolve</Tag>
      //       </Popconfirm>
      //       <Tag
      //       color="red"
      //           style={{ cursor: "pointer" }}
      //         // onClick={handleNotify}
      //       >
      //         Notify
      //       </Tag>
      //     </div>
      //   );
      // },
    },
  ];

  const stats = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
        >
          <path
            d="M18 4L10 9L2 4V2L10 7L18 2M18 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2C20 0.89 19.1 0 18 0Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Total Emails Sent",
      value: cardData.total_sent,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_4252_4006)">
            <path
              d="M10.63 14.1C12.23 10.58 16.38 9.03 19.9 10.63C23.42 12.23 24.97 16.38 23.37 19.9C22.24 22.4 19.75 24 17 24C14.3 24 11.83 22.44 10.67 20H1V18C1.06 16.86 1.84 15.93 3.34 15.18C4.84 14.43 6.72 14.04 9 14C9.57 14 10.11 14.05 10.63 14.1ZM9 4C10.12 4.03 11.06 4.42 11.81 5.17C12.56 5.92 12.93 6.86 12.93 8C12.93 9.14 12.56 10.08 11.81 10.83C11.06 11.58 10.12 11.95 9 11.95C7.88 11.95 6.94 11.58 6.19 10.83C5.44 10.08 5.07 9.14 5.07 8C5.07 6.86 5.44 5.92 6.19 5.17C6.94 4.42 7.88 4.03 9 4ZM17 22C18.3261 22 19.5979 21.4732 20.5355 20.5355C21.4732 19.5979 22 18.3261 22 17C22 15.6739 21.4732 14.4021 20.5355 13.4645C19.5979 12.5268 18.3261 12 17 12C15.6739 12 14.4021 12.5268 13.4645 13.4645C12.5268 14.4021 12 15.6739 12 17C12 18.3261 12.5268 19.5979 13.4645 20.5355C14.4021 21.4732 15.6739 22 17 22ZM16 14H17.5V16.82L19.94 18.23L19.19 19.53L16 17.69V14Z"
              fill="#74003B"
            />
          </g>
          <defs>
            <clipPath id="clip0_4252_4006">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: "Pending Client Response",
      value: cardData.pending_response,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.5 12C16.8283 12 17.1534 11.9353 17.4567 11.8097C17.76 11.6841 18.0356 11.4999 18.2678 11.2678C18.4999 11.0356 18.6841 10.76 18.8097 10.4567C18.9353 10.1534 19 9.8283 19 9.5C19 8.83696 18.7366 8.20107 18.2678 7.73223C17.7989 7.26339 17.163 7 16.5 7C15.837 7 15.2011 7.26339 14.7322 7.73223C14.2634 8.20107 14 8.83696 14 9.5C14 9.8283 14.0647 10.1534 14.1903 10.4567C14.3159 10.76 14.5001 11.0356 14.7322 11.2678C15.2011 11.7366 15.837 12 16.5 12ZM9 11C9.79565 11 10.5587 10.6839 11.1213 10.1213C11.6839 9.55871 12 8.79565 12 8C12 7.20435 11.6839 6.44129 11.1213 5.87868C10.5587 5.31607 9.79565 5 9 5C8.20435 5 7.44129 5.31607 6.87868 5.87868C6.31607 6.44129 6 7.20435 6 8C6 8.79565 6.31607 9.55871 6.87868 10.1213C7.44129 10.6839 8.20435 11 9 11ZM16.5 14C14.67 14 11 14.92 11 16.75V19H22V16.75C22 14.92 18.33 14 16.5 14ZM9 13C6.67 13 2 14.17 2 16.5V19H9V16.75C9 15.9 9.33 14.41 11.37 13.28C10.5 13.1 9.66 13 9 13Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Pending Actions by Admin",
      value: cardData.pending_admin_actions,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Completed Cases",
      value: cardData.total_completed,
    },
  ];

  const handleMessageChange = (e: any) => {
    setMessageState(e.target.value);
  };

  return (
    <>
      <div className="business-table">
        <div className="invoice-request-header">
          <div className="stats-section">
            {stats.map((stat, index) => (
              <StatCard
                key={index}
                icon={stat.icon}
                label={stat.label}
                value={stat.value}
                // color={stat.color}
              />
            ))}
          </div>
        </div>
        <div style={{ width: "92%", margin: "0 auto" }}>
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: "100%" }}
          >
            <CustomAgGrid
              columnDefs={columnDefs}
              rowData={tableData}
              enableFilter={true}
              enableSort={true}
              enablePagination={false}
              enableCheckbox={false}
            />
          </div>
        </div>
        <Modal
          title="Notify Users"
          open={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => handleUpdateStatus(true, selectedRow)}
        >
          <div>
            <TextArea
              value={messageState}
              onChange={handleMessageChange}
              placeholder="Enter a message"
              autoSize={{ minRows: 3, maxRows: 5 }}
              style={{ borderColor: "#bebebe" }}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};
export default MailStatus;
