import { useNavigate } from "react-router-dom";
import CustomAgGrid, { ColumnDefinition } from "../../shared/AgGrid";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Avatar,
  Modal,
  Checkbox,
  Form,
  Input,
  Select,
  Space,
  message,
  Dropdown,
  MenuProps,
  Popconfirm,
  Tag,
} from "antd";

import {
  UserOutlined,
  IdcardOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import "./UserDetails.scss";
import BuildingIcon from "../../assets/images/BuildingOffice.png";
import UserIcon from "../../assets/images/User.png";
import TrashIcon from "../../assets/images/TrashIcon.png";
import { API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE } from "app/shared/MasterLayout/masterlayout.constant";
import EditIcon from "../../assets/images/EditIcon.png";
import { useRecoilState } from "recoil";
import { userInfo, workspaceInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import {
  API_ENDPOINT_CORPORATE_INFO_UPDATE,
  API_ENDPOINT_USER_INFO_UPDATE,
  API_ENDPOINT_UPDATED_USER_INFO,
} from "app/scenes/Auth/auth.constant";
import EditCorporateModal from "./EditModals/EditCorporateModal";
import EditUserModal from "./EditModals/EditUserModal";
import EditPANModal from "./EditModals/EditPanModal";
import Typography from "../Typography";
import EditTMCModal from "./EditModals/EditTMCModal";
import DelinkUserModal from "./DeleteModals/DeleteUserModal"

// Set the AG Grid Enterprise license key

interface UserData {
  name: string;
  email: string;
  mobile: number;
  joined: string;
  // categories: string;
}

interface PanData {
  pan: string;
  entityName: string;
  gstins: string;
}

// Sample data

const UserDetails: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCompanyEditOpen, setIsCompanyEditOpen] = useState(false);
  const [isPersonalEditOpen, setIsPersonalEditOpen] = useState(false);
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [panData, setPanData] = useState<any[]>([]);

  const [category, setCategory] = useState(selectedRow?.type || "");
  const [companyName, setCompanyName] = useState(selectedRow?.company || "");

  // const [rowData, setRowData] = useState<any[]>([]);
  const navigate = useNavigate();
  //toggle
  const [isUsersExpanded, setIsUsersExpanded] = useState(false);
  const [isCorporatesExpanded, setIsCorporatesExpanded] = useState(false);
  const [isPANExpanded, setIsPANExpanded] = useState(false);
  const [isTMCExpanded, setIsTMCExpanded] = useState(false);

  //visibility

  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const [isDelinkUserModalVisible, setDelinkUserModalVisible] = useState(false);
  const [isCorporateModalVisible, setCorporateModalVisible] = useState(false);
  const [isTMCModalVisible, setTMCModalVisible] = useState(false);
  const [isPANModalVisible, setPANModalVisible] = useState(false);

  // const [selectedRow, setSelectedRow] = useState<any>(null);
  useEffect(() => {
    // Prefill form fields with data from Recoil state

    setName(userDetails?.workspaceInfo?.company || "");
    setWebsite(userDetails?.workspaceInfo?.website || "");
    setPAN(userDetails?.workspaceInfo?.pan || "");
    setUserName(userDetails?.userInfo?.name || "");
    setUserEmail(userDetails?.userInfo?.email || "");
    setUserMobile(userDetails?.userInfo?.phone || "");
  }, [userDetails]);

  // Add toggle function for Users, corporate and PAN section
  const toggleUsersSection = () => {
    setIsUsersExpanded(!isUsersExpanded);
    setIsPANExpanded(false);
    setIsCorporatesExpanded(false);
    setIsTMCExpanded(false);
  };

  const togglePANSection = () => {
    setIsPANExpanded(!isPANExpanded);
    setIsUsersExpanded(false);
    setIsCorporatesExpanded(false);
    setIsTMCExpanded(false);
  };

  const toggleCorporatesSection = () => {
    setIsCorporatesExpanded(!isCorporatesExpanded);
    setIsPANExpanded(false);
    setIsUsersExpanded(false);
    setIsTMCExpanded(false);
  };

  const toggleTMCSection = () => {
    setIsTMCExpanded(!isTMCExpanded);
    setIsPANExpanded(false);
    setIsUsersExpanded(false);
    setIsCorporatesExpanded(false);
  };

  const openPANEditModal = (row: any) => {
    setSelectedRow(row);
    setPANModalVisible(true);
  };

  const openTMCEditModal = (row: any) => {
    setSelectedRow(row);
    setTMCModalVisible(true);
  };

  const openCorporateEditModal = (row: any) => {
    setSelectedRow(row);
    setCorporateModalVisible(true);
  };

  const openUserEditModal = (row: any) => {
    setSelectedRow(row);
    setUserModalVisible(true);
  };

  const openUserDelinkModal = (row: any) => {
    setSelectedRow(row);
    setDelinkUserModalVisible(true);
  };

  const handleInviteUserClick = () => {
    // setIsInviteUserModalVisible(true);
    navigate("/app/invite/user");
  };

  const handleAddPanClick = () => {
    // setIsAddPanModalVisible(true);
    navigate("/app/add/pan");
  };
  const handleAddCorporateClick = () => {
    // setIsAddPanModalVisible(true);
    navigate("/app/add/corporate");
  };

  const handleInviteTMCClick = () => {
    // setIsInviteTMCModalVisible(true);
    navigate("/app/invite/tmc");
  };

  const onUserFinish = (updatedValues: any) => {
    const payload = { ...updatedValues, user_id: selectedRow.user_id };
    // setRowData(updatedRowData); // Update the state
    setUserModalVisible(false); // Close modal

    // Pass the updated values plus the user_id as payload
    handleUserTableUpdate(payload);
  };

  const onCorporateFinish = (updatedValues: any) => {
    const payload = { ...selectedRow, ...updatedValues };
    // setRowData(updatedRowData); // Update the state
    setCorporateModalVisible(false); // Close modal

    // Pass the updated values plus the user_id as payload
    handleCorporateTableUpdate(payload);
  };

  const onFinish = (updatedValues: any) => {
    // console.log("Form Submitted with Values:", updatedValues);

    const payload = { ...updatedValues, user_id: selectedRow.user_id };
    // console.log("Payload + userId:", payload);
    // Update the rowData with the new values
    // const updatedRowData = rowData.map((row) =>
    //   row.user_id === selectedRow.user_id ? { ...row, ...updatedValues } : row
    // );

    // setRowData(updatedRowData); // Update the state
    setIsModalVisible(false); // Close modal

    // Pass the updated values plus the user_id as payload
    // handleUserTableUpdate(payload);
    // handleCorporateTableUpdate(payload);
  };

  const handleUserTableUpdate = async (payload: any) => {
    console.log("Sending Payload :", payload);
    const response = await apiPost(
      `${API_ENDPOINT_USER_INFO_UPDATE}/${payload.user_id}`,
      payload
    );
    if (response.status) {
      const newUserObj = {
        account_type: response.data?.account_type,
        created_at: response.data.created_at,
        email: response.data.email,
        user_id: response.data?.id,
        linkedin_profile: response.data.linkedin_profile,
        last_updated: response.data.last_updated,
        name: response.data.name,
        phone: response.data.phone,
      };

      if (response.data?.account_type === "TAX_MANAGER") {
        //update Tax manager array
        console.log("Adding to Tax Manager Array");
        setUserDetails((prev: any) => ({
          ...prev,
          tax_managers: [
            newUserObj,
            ...(prev.tax_managers?.filter(
              (tm: any) => tm.user_id !== newUserObj.user_id
            ) || []),
          ],
          travel_contacts: [
            ...(prev.travel_contacts?.filter(
              (tc: any) => tc.user_id !== newUserObj.user_id
            ) || []),
          ],
        }));
      } else if (response.data?.account_type === "TRAVEL_CONTACT") {
        //update Travel Contact array
        console.log("Adding to Travel Contact Array");
        setUserDetails((prev: any) => ({
          ...prev,
          travel_contacts: [
            newUserObj,
            ...(prev.travel_contacts?.filter(
              (tc: any) => tc.user_id !== newUserObj.user_id
            ) || []),
          ],
          tax_managers: [
            ...(prev.tax_managers?.filter(
              (tm: any) => tm.user_id !== newUserObj.user_id
            ) || []),
          ],
        }));
      }
    } else {
      console.error("Error sending data:");
    }
  };

  const handleCorporateTableUpdate = async (payload: any) => {
    console.log("Sending Payload :", payload);
    const response = await apiPost(
      `${API_ENDPOINT_CORPORATE_INFO_UPDATE}/${payload.id}`,
      payload
    );
    if (response.status) {
      const newWorkspace = {
        id: response.data?.id, // Extract the id from the API response
        company: response.data.name,
        website: response.data.website,
        pan: response.pan_data, // Directly pulling pan from entered pan
        created_at: response.data.created_at,
        last_updated: response.data.last_updated,
        type: response.data.type,
      };

      setUserDetails((prev: any) => ({
        ...prev,
        workspaceList: prev.workspaceList?.some(
          (workspace: any) => workspace.id === newWorkspace.id
        )
          ? prev.workspaceList.map((workspace: any) =>
              workspace.id === newWorkspace.id
                ? { ...workspace, ...newWorkspace }
                : workspace
            )
          : [...(prev.workspaceList || []), newWorkspace],
      }));
      console.log("Corporate Update Response", response);
    } else {
      console.error("Error sending data:");
    }
  };

  const handleCancel = () => {
    setPANModalVisible(false);
    setCorporateModalVisible(false);
    setTMCModalVisible(false);
    setUserModalVisible(false);
    setSelectedRow(null);
    setIsPersonalEditOpen(false);
    setIsCompanyEditOpen(false);
    setDelinkUserModalVisible(false);
  };

  //column definitions for Pan section

  const pancolumnDefs: ColumnDefinition[] = [
    {
      headerName: "PAN",
      field: "pan",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Entity Name",
      field: "entity_name",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "No. of GSTINS",
      field: "gstin_count",
      type: "number",
      enableRowGroup: true,
    },
    {
      headerName: "Invoice Access Status",
      field: "status",
      type: "text",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            {params.data?.status === "ACCEPTED" ? (
              <Tag color="green">ACCEPTED</Tag>
            ) : params.data?.status === "PENDING" ? (
              <Tag color="red">PENDING</Tag>
            ) : params.data?.status === "ESCALATION_RESOLVED" ? (
              <Tag color="yellow">ESCALATION_RESOLVED</Tag>
            ) : params.data?.status === "ADMIN" ? (
              <Tag color="darkseagreen">ADMIN ACCESS</Tag>
            ) : (
              <Tag color="red">{params.data?.status}</Tag>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      enableRowGroup: true,
      cellRenderer: (params: any) => (
        <div
          className="action-buttons"
          style={{ display: "flex", justifyContent: "flex-start", gap: "15px" }}
        >
          <Button
            type="primary"
            onClick={() => openPANEditModal(params.data)}
            style={{
              borderRadius: "6px",
              border: "0.781px solid #1976D2",
              background: "#EAF3FE",
              padding: "4px 8px",
              color: "#1A73E8",
              fontSize: "12px",
            }}
            size="small"
          >
            Edit
          </Button>
          {/* <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            okButtonProps={{style:{background:'#74003B', borderColor:'#74003B'}}}
            cancelText="No"
            cancelButtonProps={{
              style: { color: "#74003B", border: "1px solid #74003B" },
            }}
            
          >
          <Button
            type="primary"
            style={{
              borderRadius: "6px",
              border: "0.781px solid #C62828",
              background: "#FDECEA",
              padding: "4px 8px",
              color: "#D32F2F",
              fontSize: "12px",
              width: "45%",
            }}
            // onClick={() => handleDelete(params.data)}
          >
            Delete
          </Button>
          </Popconfirm> */}
        </div>
      ),
    },
  ];

  // Column definitions
  const corporateColumnDefs: ColumnDefinition[] = [
    {
      headerName: "Name",
      field: "company",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Website",
      field: "website",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Categories",
      field: "type",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Entity Type",
      field: "entity_type",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "No. of PANs",
      field: "pan_count",
      type: "number",
      enableRowGroup: false,
    },
    {
      headerName: "Action",
      field: "action",
      enableRowGroup: true,
      cellRenderer: (params: any) => (
        <div
          className="action-buttons"
          style={{ display: "flex", justifyContent: "flex-start", gap: "15px" }}
        >
          <Button
            type="primary"
            onClick={() => openCorporateEditModal(params.data)}
            style={{
              borderRadius: "6px",
              border: "0.781px solid #1976D2",
              background: "#EAF3FE",
              padding: "4px 8px",
              color: "#1A73E8",
              fontSize: "12px",
            }}
            size="small"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  //column definitions for tmc
  const tmcColumnDefs: ColumnDefinition[] = [
    {
      headerName: "Name",
      field: "company",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Website",
      field: "website",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Categories",
      field: "type",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Entity Type",
      field: "entity_type",
      type: "text",
      enableRowGroup: true,
    },

    {
      headerName: "Action",
      field: "action",
      enableRowGroup: true,
      cellRenderer: (params: any) => (
        <div
          className="action-buttons"
          style={{ display: "flex", justifyContent: "flex-start", gap: "15px" }}
        >
          <Button
            type="primary"
            onClick={() => openTMCEditModal(params.data)}
            style={{
              borderRadius: "6px",
              border: "0.781px solid #1976D2",
              background: "#EAF3FE",
              padding: "4px 8px",
              color: "#1A73E8",
              fontSize: "12px",
            }}
            size="small"
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const userColumnDefs: ColumnDefinition[] = [
    {
      headerName: "Name",
      field: "name",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Mobile",
      field: "phone",
      type: "number",
      enableRowGroup: true,
    },
    {
      headerName: "Joined",
      field: "joined",
      type: "text",
      enableRowGroup: true,
    },
    {
      headerName: "Categories",
      field: "workspace_role",
      type: "text",
      enableRowGroup: true,
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            {params.data?.workspace_role === "TAX_MANAGER" ? (
              <Tag color="green">Tax Manager</Tag>
            ) : params.data?.workspace_role === "TRAVEL_CONTACT" ? (
              <Tag color="blue">Travel Contact</Tag>
            ) : params.data?.workspace_role === "GENERAL_USER" ? (
              <Tag color="yellow">General User</Tag>
            ) : (
              <Tag color="red">{params.data?.workspace_role}</Tag>
            )}
          </div>
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      enableRowGroup: true,
      cellRenderer: (params: any) => (
        <div
          className="action-buttons"
          style={{ display: "flex", justifyContent: "flex-start", gap: "15px" }}
        >
          <Button
            type="primary"
            onClick={() => openUserEditModal(params.data)}
            style={{
              borderRadius: "6px",
              border: "0.781px solid #1976D2",
              background: "#EAF3FE",
              padding: "4px 8px",
              color: "#1A73E8",
              fontSize: "12px",
            }}
            size="small"
          >
            Edit
          </Button>

          <Button
            type="primary"
            onClick={() => openUserDelinkModal(params.data)}
            style={{
              borderRadius: "6px",
              border: "0.781px solid #efbac4",
              background: "#fcf1f3",
              padding: "4px 8px",
              color: "#de6e84",
              fontSize: "12px",
            }}
            size="small"
          >
            Delink
          </Button>
        </div>
      ),
    },
  ];

  const showCompanyEditModal = () => {
    setIsCompanyEditOpen(true);
  };

  const showPersonalEditModal = () => {
    setIsPersonalEditOpen(true);
  };

  const handleCompanyDetailsOk = () => {
    setLoading(true);
    // setIsCompanyEditOpen(false);
    handleCompanySubmit();
    setTimeout(() => {
      setIsCompanyEditOpen(false);
      setLoading(false);
    }, 2000);
  };

  const handlePersonalDetailsOk = () => {
    setLoading(true);
    handlePersonalSubmit();
    // setIsPersonalEditOpen(false);
    setTimeout(() => {
      setIsPersonalEditOpen(false);
      setLoading(false);
    }, 2000);
  };

  const isValidWebsite = (url: string) => {
    const websitePattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return websitePattern.test(url);
  };

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan);
  };

  const handleCompanySubmit = async () => {
    if (!name.trim()) {
      message.error("Company name is required.");
      return;
    }

    // if (!isValidWebsite(website)) {
    //   message.error("Please enter a valid website (e.g., .com, .in).");
    //   return;
    // }

    if (!isValidPAN(PAN)) {
      message.error(
        "Please enter a valid PAN number (10 alphanumeric characters)."
      );
      return;
    }
    setLoading(true);
    // Handle form submission logic
    const operationType =
      userDetails?.workspaceList?.length > 0 ? "UPDATE" : "CREATE";

    // Handle if Same Name Added
    // const newName = (userDetails?.workspaceList).find(
    //   (workspace:any) => workspace.company === name
    // );

    // console.log("New Name Status: ", newName)

    // if(newName && userDetails?.workspaceInfo?.company !== name) {
    //   return message.error("Workspace with that name already exists, please switch to it, or add corporate")
    // }

    const payload = {
      oldcompany:
        userDetails?.currentWorkspace?.company ||
        userDetails?.workspaceInfo?.company,
      oldPan:
        userDetails?.currentWorkspace?.pan || userDetails?.workspaceInfo?.pan,
      company: name,
      pan: PAN,
      website: website,
      operationType: operationType,
      type:
        userDetails?.currentWorkspace?.type || userDetails?.workspaceInfo?.type,
      id: userDetails?.currentWorkspace?.id || userDetails?.workspaceInfo?.id,
      isAdmin:
        userDetails.userInfo?.email?.includes("kgrp.in") ||
        userDetails.userInfo?.email?.includes("finkraft.ai")
          ? true
          : false,
    };

    console.log("payload of edit corporate:", payload);

    const response = await apiPost(API_ENDPOINT_CORPORATE_INFO_UPDATE, payload);

    const userresponse = await apiPost(
      `${API_ENDPOINT_UPDATED_USER_INFO}`,
      payload
    );

    console.log("New User Info: ", userresponse?.data);

    if (response.status) {
      // const newWorkspace = {
      //   box_folder_id: response.boxId?.id,
      //   id: response.data?.id, // Extract the id from the API response
      //   company: response.data?.name,
      //   website: response.data?.website,
      //   pan: response.pan_data, // Directly pulling pan from entered pan
      //   created_at: response.data?.created_at,
      //   last_updated: response.data?.last_updated,
      //   // type:"TMC"
      // };

      // const newPanObj = {
      //   pan: response.pan_data,
      //   entity_id: response.data?.id,
      //   entity_name: response.data?.name,
      // };

      // setUserDetails((prev: any) => ({
      //   ...prev,
      //   company: name,
      //   pan: PAN,
      //   website: website,
      //   pans: prev.pans?.some((pan: any) => pan.pan === newPanObj.pan)
      //     ? prev.pans
      //     : [...(prev.pans || []), newPanObj],
      //   workspaceList: prev.workspaceList?.some(
      //     (workspace: any) => workspace.id === newWorkspace.id
      //   )
      //     ? prev.workspaceList.map((workspace: any) =>
      //         workspace.id === newWorkspace.id
      //           ? { ...workspace, ...newWorkspace }
      //           : workspace
      //       )
      //     : [...(prev.workspaceList || []), newWorkspace],
      //   workspaceInfo: { ...prev.workspaceInfo, ...newWorkspace },
      // }));

      // to fetch the active Pan Object
      const panObj = (userresponse?.data?.workspaceInfo?.panlist).find(
        (panobj: any) => panobj.pan === PAN
      );
      console.log("Updated Pan Obj: ", panObj);

      setUserDetails((prev: any) => ({
        ...prev,
        ...userresponse?.data,
        workspaceInfo: {
          ...userresponse?.data?.workspaceInfo,
          pan: panObj.pan,
          box_folder_id: panObj.box_folder_id,
        },
        currentWorkspace: {
          ...userresponse?.data?.workspaceInfo,
          pan: panObj.pan,
          box_folder_id: panObj.box_folder_id,
        },
      }));
      message.success("Company details updated");
      navigate("/app/userdetails");
    } else if (!response.status) {
      if (response?.error_code === "404") {
        console.log("WORKSPACE ALREADY EXISTS");
        message.error(
          `Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`
        );
      } else if (response?.error_code === "402") {
        console.log("PAN ALREADY EXISTS");
        message.error(
          `PAN: ${response?.error} already exists, Please enter Unique PAN`
        );
      }
    } else {
      message.error("Could not update company details");
    }
    setLoading(false);
  };

  const handlePersonalSubmit = async () => {
    setLoading(true);

    const payload = {
      user_id: userDetails.userInfo.id,
      name: userName,
      email: userEmail,
      phone: userMobile,
      account_type: userDetails.userInfo?.account_type,
    };

    const response = await apiPost(
      `${API_ENDPOINT_USER_INFO_UPDATE}/${payload.user_id}`,
      payload
    );
    if (response.status) {
      const newUserInfo = {
        account_type: response.data?.account_type,
        created_at: response.data.created_at,
        email: response.data.email,
        id: response.data.id,
        invoice_access: response.data.invoice_access,
        last_updated: response.data.last_updated,
        name: response.data.name,
        phone: response.data.phone,
        status: response.data.status,
      };

      setUserDetails((prev: any) => ({
        ...prev,
        userInfo: newUserInfo,
      }));

      message.success("Personal Details updated");
      navigate("/app/userdetails");
    } else {
      message.error("Could not update Personal Details");
    }
    setLoading(false);
  };

  const items: MenuProps["items"] = (
    userDetails?.workspaceInfo?.panlist || []
  ).map((pan: any, index: any) => ({
    label: (
      <a href="#" target="_blank" rel="noopener noreferrer">
        {pan.entity_name}
      </a>
    ),
    key: `${index}`, // Unique key for each item
  }));

  return (
    <div className="dashboard-container">
      {/* <Typography variant="xs" color="#877d7d">
        *Go to dashboard to add,invite users and corporates
      </Typography> */}
      {!(
        userDetails?.userInfo?.email?.includes("finkraft.ai") ||
        userDetails?.userInfo?.email?.includes("kgrp.in")
      ) && (
        <div className="info-cards">
          <div className="company-card">
            <div className="card-header">
              <div className="card-header-1">
                <div className="card-icon">
                  <img src={BuildingIcon} alt="icon" />
                </div>
                <h3>Company Details</h3>
              </div>

              <Button onClick={showCompanyEditModal} className="card-header-2">
                Edit
              </Button>
              <Modal
                title="Edit Company Details"
                open={isCompanyEditOpen}
                onOk={handleCompanyDetailsOk}
                onCancel={handleCancel}
                okButtonProps={{
                  loading: loading,
                  style: { backgroundColor: "#74003B", borderColor: "#74003B" },
                }}
                cancelButtonProps={{
                  style: { color: "#74003B", border: "1px solid #74003B" },
                }}
              >
                <form onSubmit={handleCompanySubmit}>
                  <div className="tax-manager-info-container">
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <label htmlFor="email">Company Name *</label>
                      <Input
                        type="text"
                        // defaultValue=" "
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter company name"
                        style={{ borderColor: "#C3C4CD" }}
                      />
                    </div>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <label htmlFor="email">Company Website *</label>
                      <Input
                        type="text"
                        // defaultValue=" "
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        placeholder="Enter company website"
                        style={{ borderColor: "#C3C4CD" }}
                      />
                    </div>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <label htmlFor="email">PAN Number</label>
                      <Input
                        type="text"
                        // defaultValue=" "
                        value={PAN}
                        onChange={(e) => setPAN(e.target.value.toUpperCase())}
                        placeholder="Enter PAN Number"
                        style={{ borderColor: "#C3C4CD" }}
                      />
                    </div>
                  </div>
                </form>
              </Modal>
            </div>
            <div className="card-content">
              <div className="card-row">
                <div className="card-content-label"> Company Name: </div>
                <div className="card-content-value">
                  {userDetails?.workspaceInfo?.company}
                </div>{" "}
              </div>
              <div className="card-row">
                <div className="card-content-label">Business Type: </div>
                <div className="card-content-value">
                  {" "}
                  {userDetails.workspaceInfo?.type}
                </div>
              </div>
              <div className="card-row">
                <div className="card-content-label">PAN: </div>
                <div className="card-content-value">
                  {" "}
                  {userDetails?.workspaceInfo?.panlist[0]?.pan}
                </div>
              </div>
            </div>
          </div>

          <div className="personal-card">
            <div className="card-header">
              <div className="card-header-1">
                <div className="card-icon">
                  <img src={UserIcon} alt="icon" />
                </div>
                <h3>Personal Details</h3>
              </div>

              <Button onClick={showPersonalEditModal} className="card-header-2">
                Edit
              </Button>
              <Modal
                title="Edit Personal Details"
                open={isPersonalEditOpen}
                onOk={handlePersonalDetailsOk}
                onCancel={handleCancel}
                okButtonProps={{
                  loading: loading,
                  style: { backgroundColor: "#74003B", borderColor: "#74003B" },
                }}
                cancelButtonProps={{
                  style: { color: "#74003B", border: "1px solid #74003B" },
                }}
              >
                <form onSubmit={handlePersonalSubmit}>
                  <div className="form-group" style={{ marginTop: "30px" }}>
                    <label htmlFor="email">Name *</label>
                    <Input
                      type="text"
                      // defaultValue=" "
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter your name"
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "30px" }}>
                    <label htmlFor="email">Email *</label>
                    <Input
                      type="text"
                      // defaultValue=" "
                      value={userEmail}
                      disabled
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="Enter email address"
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "30px" }}>
                    <label htmlFor="email">Mobile *</label>
                    <Input
                      type="text"
                      // defaultValue=" "
                      value={userMobile}
                      onChange={(e) => setUserMobile(e.target.value)}
                      placeholder="Enter Mobile Number"
                      style={{ borderColor: "#C3C4CD" }}
                    />
                  </div>
                </form>
              </Modal>
            </div>
            <div className="card-content">
              <div className="card-row">
                <div className="card-content-label">Name:</div>
                <div className="card-content-value">
                  {userDetails?.userInfo?.name}
                </div>
              </div>
              <div className="card-row">
                <div className="card-content-label">Email:</div>
                <div className="card-content-value">
                  {userDetails?.userInfo?.email}
                </div>
              </div>
              <div className="card-row">
                <div className="card-content-label">Mobile:</div>
                <div className="card-content-value">
                  {userDetails?.userInfo?.phone}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="section-container">
        {/* PAN Section */}
        <div>
          <div
            className="add-corporates-section"
            onClick={togglePANSection}
            style={{ cursor: "pointer" }}
          >
            <div className="content-text">PAN</div>
            <div
              style={{
                width: "12%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="primary"
                // htmlType="submit"
                onClick={handleAddPanClick}
                loading={isLoading}
                style={{
                  backgroundColor: "#74003B",
                  borderColor: "#74003B",
                  width: "77%",
                  // left:'455px',
                }}
              >
                Add PAN
              </Button>

              <div style={{ cursor: "pointer" }}>
                {isPANExpanded ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", background: "#FFFFFF" }}>
            {isPANExpanded && (
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: "100%" }}
              >
                <CustomAgGrid
                  columnDefs={pancolumnDefs}
                  // rowData={rowData}
                  rowData={[...(userDetails?.workspaceInfo?.panlist || [])]}
                  enableFilter={true}
                  enableSort={true}
                  enablePagination={false}
                  enableCheckbox={false}
                />
              </div>
            )}
          </div>
        </div>
        <EditPANModal
          isVisible={isPANModalVisible}
          selectedRow={selectedRow}
          onCancel={handleCancel}
        />

        {/* Corporates Section */}
        {/* <div>
          <div
            className="add-corporates-section"
            onClick={toggleCorporatesSection}
            style={{ cursor: "pointer" }}
          >
            <div className="content-text">Corporates</div>
            <div
              style={{
                width: "12%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ cursor: "pointer" }}>
                {isCorporatesExpanded ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", background: "#FFFFFF" }}>
            {isCorporatesExpanded && (
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: "100%" }}
              >
                <CustomAgGrid
                  columnDefs={corporateColumnDefs}
                  rowData={[...(userDetails?.workspaceList || [])]}
                  enableFilter={true}
                  enableSort={true}
                  enablePagination={false}
                  enableCheckbox={false}
                />
              </div>
            )}
          </div>
        </div> */}
        <EditCorporateModal
          isVisible={isCorporateModalVisible}
          selectedRow={selectedRow}
          onCancel={handleCancel}
        />

        {/* tmc Section */}
        <div>
          <div
            className="add-corporates-section"
            onClick={toggleTMCSection}
            style={{ cursor: "pointer" }}
          >
            <div className="content-text">TMC</div>
            <div
              style={{
                width: "12%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="primary"
                // htmlType="submit"
                onClick={handleInviteTMCClick}
                loading={isLoading}
                style={{
                  backgroundColor: "#74003B",
                  borderColor: "#74003B",
                  width: "77%",
                  // left:'456px',
                }}
              >
                Add TMC
              </Button>
              <div style={{ cursor: "pointer" }}>
                {isTMCExpanded ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", background: "#FFFFFF" }}>
            {isTMCExpanded && (
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: "100%" }}
              >
                <CustomAgGrid
                  columnDefs={tmcColumnDefs}
                  rowData={[...(userDetails?.TMCList || [])]}
                  enableFilter={true}
                  enableSort={true}
                  enablePagination={false}
                  enableCheckbox={false}
                />
              </div>
            )}
          </div>
        </div>
        <EditTMCModal
          isVisible={isTMCModalVisible}
          selectedRow={selectedRow}
          onCancel={handleCancel}
        />

        {/* Users Section */}
        <div>
          <div
            className="add-corporates-section"
            onClick={toggleUsersSection}
            style={{ cursor: "pointer" }}
          >
            <div className="content-text">Users</div>
            <div
              style={{
                width: "12%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="primary"
                // htmlType="submit"
                onClick={handleInviteUserClick}
                loading={isLoading}
                style={{
                  backgroundColor: "#74003B",
                  borderColor: "#74003B",
                  width: "77%",
                  // left:'456px',
                }}
              >
                Add User
              </Button>
              <div style={{ cursor: "pointer" }}>
                {isUsersExpanded ? <UpOutlined /> : <DownOutlined />}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", background: "#FFFFFF" }}>
            {isUsersExpanded && (
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: "100%" }}
              >
                <CustomAgGrid
                  columnDefs={userColumnDefs}
                  rowData={[
                    ...(userDetails?.tax_managers || []),
                    ...(userDetails?.travel_contacts || []),
                    ...(userDetails?.genericUsers || []),
                  ]}
                  enableFilter={true}
                  enableSort={true}
                  enablePagination={false}
                  enableCheckbox={false}
                  // enableRowGroup={true}
                />
              </div>
            )}
          </div>
        </div>
        <EditUserModal
          isVisible={isUserModalVisible}
          selectedRow={selectedRow}
          onCancel={handleCancel}
        />
        <DelinkUserModal
          isVisible={isDelinkUserModalVisible}
          selectedRow={selectedRow}
          onCancel={handleCancel}
        />
      </div>
    </div>
  );
};

export default UserDetails;
