import React from "react";
import "./HelpSection.scss";
import SupportAgent from "../../assets/images/support-agent.png";
import LocationIcon from "../../assets/images/EnvironmentOutlined.png";
import PhoneIcon from "../../assets/images/PhoneOutlined.png";
import MailIcon from "../../assets/images/MailOutlined.png";
import { show } from "@intercom/messenger-js-sdk";

const HelpSection = () => {
  const handleOpenChat = () => {
    show();
  };
  return (
    <div className="help-container" id="help-section">
      <h2>Still need help?</h2>
      <p>Call us or mail us, we will get back to you</p>
      <div className="content-wrapper">
        <div className="contact-info">
          <h3>Talk to an agent via email</h3>

          <div className="info-item">
            <img src={MailIcon} alt="mail" />
            <span>qatarairways@finkraft.ai</span>
          </div>
        </div>
        <div className="customer-support-container">
          {/* <div className="image-container">
            <img src={SupportAgent} alt="Customer support agent" />
          </div> */}
          <div className="support-options">
            <h3>
              <b>Talk to an agent via chat bot</b>
            </h3>
            <button className="connect-button" onClick={handleOpenChat}>
              Let's connect
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
