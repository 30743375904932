import React, { useEffect, useState } from "react";
import { message, Layout, Spin } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import "./MasterLayout.scss";
import PrimaryNav from "../PrimaryNav";
import PrimaryHeader from "../PrimaryHeader";

import Sidebar from "../Sidebar";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  boxPanList,
  selectedPanForBox,
  userInfo,
  hardRefresh,
} from "app/config/States/users";

import { API_ENDPOINT_META_USER_INFO } from "app/scenes/Auth/auth.constant";
import { apiGet } from "app/services/apiServices";

const MasterLayout = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const refreshTrigger = useRecoilValue(hardRefresh);
  const [panBoxList, setBoxPanList] = useRecoilState<any>(boxPanList);
  const [selectedPAN, setSelectedPan] = useRecoilState<any>(selectedPanForBox);

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  // return loading ? (
  //   <div className="LoadingContainer">
  //     <Spin size="large" />
  //   </div>
  // ) : (

  const fetchUserLogin = async () => {
    const response = await apiGet(API_ENDPOINT_META_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: response.data?.workspaceInfo,
      });

      setBoxPanList(response.data?.workspaceInfo?.panlist);
      // console.log("Updated Pan List: ", panBoxList)
      setSelectedPan(response.data?.workspaceInfo?.panlist?.[0]?.pan);
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaceInfo?.box_folder_id
      );
      localStorage.setItem(
        "currentWorkspaceId",
        response.data?.workspaceInfo?.id
      );
      navigate("/app");
    } else {
      message.error("Invalid credential");
    }
    setLoading(false);
  };

  
  

  const fetchUserDetails = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_META_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: response.data?.workspaceInfo,
      });
      localStorage.setItem(
        "currentBoxId",
        response.data.workspaceInfo?.box_folder_id
      );
      localStorage.setItem(
        "currentWorkspaceId",
        response.data?.workspaceInfo?.id
      );
    } else {
      message.error("Unable to fetch user details");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserLogin();
  }, []);

  // useEffect(() => {
  //   fetchUserDetails();
  // }, [refreshTrigger]); // Dependency on hardRefresh

  return (
    <div className="MainLayout">
      <div className="NavigationContainer">
        <PrimaryNav />
      </div>
      <div className="AppContentContainer">
        <div className="HeaderContainer">
          <PrimaryHeader />
        </div>
        <div className="PageContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
