import React, {useCallback, useState, useEffect} from 'react';
import './SuccessfulOnboarding.scss';
import {  message } from "antd";

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { apiPost, apiGet } from "app/services/apiServices";
import { API_ENDPOINT_META_USER_INFO } from "app/scenes/Auth/auth.constant";
import { userInfo, hardRefresh } from 'app/config/States/users';

interface SuccessMessageProps {
  title?: string;
  Message?: string;
  onPreview?: () => void;
  onSubmit?: () => void;
  handleShowPreview: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  title = "Awesome!",
  Message = "Your details has been submitted.",
  onPreview,
  onSubmit,
  handleShowPreview
}) => {
   const [hardRefreshState, setHardRefresh] = useRecoilState(hardRefresh);

  const navigate = useNavigate();
    const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
    const [isLoading, setLoading] = useState(false);
  

  //  const fetchUserDetails = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const response = await apiGet(API_ENDPOINT_META_USER_INFO);
  //     if (response.status) {
  //       // Update user details in Recoil state
  //       setUserDetails({
  //         ...response.data,
  //         currentWorkspace: response.data?.workspaceInfo,
  //       });

  //       // Update local storage with workspace and box folder information
  //       localStorage.setItem(
  //         "currentBoxId",
  //         response.data.workspaceInfo?.box_folder_id
  //       );
  //       localStorage.setItem(
  //         "currentWorkspaceId", 
  //         response.data?.workspaceInfo?.id
  //       );

  //       // Prefill form fields with fetched data
  //       // setName(response.data?.workspaceInfo?.company || "");
  //       // setWebsite(response.data?.workspaceInfo?.website || "");
  //       // setPAN(response.data?.workspaceInfo?.pan || "");
  //     } else {
  //       message.error("Unable to fetch user details");
  //     }
  //   } catch (error) {
  //     message.error("An error occurred while fetching user details");
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []); // Empty dependency array as the function doesn't depend on external variables

  // First useEffect for hardRefreshState
  // useEffect(() => {
  //   if (hardRefreshState) { 
  //     fetchUserDetails(); 
  //   } 
  // }, [hardRefreshState, fetchUserDetails]);

  // // Second useEffect for initial fetch
  // useEffect(() => {
  //   // Initial fetch when component loads
  //   fetchUserDetails();
  // }, [fetchUserDetails]);


  const handleSubmit = () => {
   
    navigate('/app/dashboard');
  };

  // const handlePreview = () => {
  //   // Navigate back to Step 1 with prefilled data
  //   navigate('/app', { state: userDetails });
  // };
  return (
    <div className="success-container">
      <div className='success-icon-container'>
      <div className="success-icon">
        <svg 
          width="24" 
          height="24" 
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      </div>
      
      <h2 className="success-title">{title}</h2>
      <p className="success-message">{Message}</p>
      <p className="success-submessage">
        Our team will verify and approve your request in few hours.
      </p>
      
      <div className="success-actions">
        <button 
          className="preview-button"
          onClick={() => {
            if (onPreview) onPreview();
          }}
        >
          Preview Details
        </button>
        {/* <button 
          className="submit-button"
          onClick={handleSubmit}
        >
          Submit
        </button> */}
      </div>
    </div>
  );
};

export default SuccessMessage;