import React, { useState , useEffect} from 'react';
import { Button, Modal, Form, Input, Checkbox, message, Divider, Select } from 'antd';
import type { FormProps } from 'antd';
import { apiPost, apiGet } from "app/services/apiServices";
import "./AddPan.scss";
import { API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo, workspaceInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";
import EntitySearch from "app/shared/EntitySearch";
import {
  ArrowLeftOutlined  
} from '@ant-design/icons';
import NewEntityModal from './NewEntity';


type FieldType = {
  company?: string;
  website?: string;
  pan?: string;
};

const AddPanModal: React.FC = ()=> {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");
  const [pan, setPan] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isAddEntityModalVisible, setAddEntityModalVisible] = useState(false); // Manage visibility of NewTmcModal
  
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const navigate = useNavigate();
  const [form] = Form.useForm(); 
    

  useEffect(() => {
      const areFieldsFilled =
        company.trim() !== "" && website.trim() !== "" && pan.trim() !== "";
      setButtonDisabled(!areFieldsFilled);
      setSelectedEntity(userDetails.workspaceInfo);
  }, [company, website, pan]);

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/; // Existing 10-character PAN format
    const worldBankPanPattern = /^[A-Z0-9]{15}$/; // New 15-character PAN format for World Bank
  
    return panPattern.test(pan) || worldBankPanPattern.test(pan);
  };
  const handleSelectWorkspace = async() => {
    setLoading(true);
    console.log("currently Selected Entity: ", selectedEntity)
  }

  const handleOk = async ()=> {
    if (!isValidPAN(pan)) {
      message.error(
        "Please enter a valid PAN number (10 or 15 alphanumeric characters)."
      );
      return;
    }

    setLoading(true);
    const fixedpan = pan.toUpperCase();
    const isAdmin = userDetails?.userInfo?.email?.includes("kgrp.in") || 
                    userDetails?.userInfo?.email?.includes("finkraft.ai") 
                    ? true 
                    : false
    setConfirmLoading(true);
    const payload = {
      pan: fixedpan,
      workspaceId: selectedEntity,
      isAdmin: isAdmin
    };
    console.log("payload is: ", payload)
    try {
          const response = await apiPost(
            API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE,
            payload
          );
          console.log("API RESPONSE: ", response)
          if (response.status) {
            const newPanObj = {
              pan: response?.data.pan,
              entityId: response?.data?.entity_id,
              entity_name: userDetails?.workspaceInfo?.company,
              // last_updated: response?.data.last_updated,
              box_folder_id: response?.boxId?.id,
              gstin_count:0,
              status:'PENDING'
            };

            setUserDetails((prev: any) => ({
              ...prev,
              pan:fixedpan,
              pans: prev.pans?.some((pan: any) => pan.pan === newPanObj.pan)
              ? prev.pans?.map((pan:any) => 
                  pan.pan === newPanObj.pan 
                    ? {...pan, ...newPanObj }
                    : pan)
              : [...(prev.pans || []), newPanObj],
              workspaceInfo: {
                ...prev?.workspaceInfo,
                panlist: prev?.workspaceInfo?.id === newPanObj.entityId 
                ? [...prev?.workspaceInfo?.panlist, newPanObj] 
                : prev?.workspaceInfo?.panlist
              }
            }));
            message.success("Pan added succesfully");
            navigate("/app/dashboard");
            // onClose();
          } else if(!response.status) {
            if (response?.error_code === "404") {
              console.log("PAN ALREADY EXISTS")
              message.error(`An Existing workspace with ${response?.error} PAN Number already exists, Please add a unique pan`);
            }
          }
          console.log("User Details after Add Pan: ",userDetails)
        } catch (error) {
          message.error("Could not add pan");
    
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
    setLoading(false);
  };

   const handleAddPan = async () => {
        setLoading(true);
       
        const payload = {
          
        };
        try {
          const response = await apiPost(
           ' API_ENDPOINT_ONBOARD_ENTITY' ,
            payload
          );
    
          if (response.status) {
            message.success("TMC has been added");
            setUserDetails((prev:any)=>({
              ...prev,
              TMCList: response?.data
            }))
            navigate(-1);
            // onClose();
          }
        } catch (error) {
          message.error("Could not add TMC");
        } finally {
          setLoading(false);
        }
      };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
  //   console.log('Success:', values);
  //   handleOk(values); // Close modal after successful form submission
  // };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <ArrowLeftOutlined style={{color:'#74003B', fontSize:'26px', padding:'15px 25px 0px',cursor: "pointer",}} onClick={handleGoBack}/>
      <div className="company-details-form">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="form-header"> Add Pan</div>

          <Form.Item<FieldType> //Pan Number
            label="PAN Number"
            name="pan"
            rules={[
              { 
              required: true, 
              message: 'Please input your PAN Number!' 
            },
            {
              pattern: /^(?:[0-9a-zA-Z]{10}|[0-9a-zA-Z]{13})$/,
              message: 'Please Enter a valid PAN Number'
            }
            ]}
          >
            <Input 
              type="text"
              onChange={(e) => setPan(e.target.value)}
            />
          </Form.Item>

          <Form.Item //Old Submit Button
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {/* <Button
              type="primary"
              color="primary"
              htmlType="submit"
              loading={isLoading}
              onClick={handleOk}
            >
              Submit
            </Button> */}
          </Form.Item>
          
          <Form.Item label="Entity Name" name="entity_name"> {/* Select Entity Form*/}
          <Select
            value = {selectedEntity}
            placeholder="Select Existing Workspace"
            optionFilterProp="label"
            onChange={(value) => setSelectedEntity(value)} 
            options={Array.from(
              new Map(
                (userDetails?.workspaceList || []).map((workspace: any) => [workspace.company, workspace])
              ).values()
            ).map((entity: any) => ({
              value: entity.id,
              label: entity.company,
            }))}
          />
          </Form.Item>

          <div // Add New Workspace Button
            style={{display:'flex', justifyContent:'flex-end', paddingTop:'10px', cursor:'pointer', color:'#74003B'}}
            onClick={() => setAddEntityModalVisible(true)}
            >
              + Add New Workspace 
          </div>

          <div // Submit Button
            style={{ display: "flex", justifyContent: "center", marginTop: 12 }}
          >
            {/* <Button type="primary" onClick={handleSelectWorkspace}>
              Select
            </Button> */}
            <Button
              type="primary"
              color="primary"
              htmlType="submit"
              loading={isLoading}
              onClick={handleOk}
            >
              Submit
            </Button>
          </div>
        </Form>

      </div>

        <NewEntityModal
        visible={isAddEntityModalVisible}
        onClose={() => setAddEntityModalVisible(false)}
        />
      {/* </Modal> */}
    </>
  );
};

export default AddPanModal;