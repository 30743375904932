import PageHeader from "app/shared/PageHeader";
import "./BoxUI.scss";

import { useEffect, useRef, useState } from "react";
import { apiGet } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import {
  boxPanList,
  selectedPanForBox,
  userInfo,
} from "app/config/States/users";
import { useRecoilState } from "recoil";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { Empty, Select, message } from "antd";
export default function BoxUI(props) {
  const [userDetails, _] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [panBoxList, __] = useRecoilState(boxPanList);
  const [selectedPAN, setSelectedPAN] = useRecoilState(selectedPanForBox);

  console.log("userDetails", userDetails);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // handleChangePan(selectedPAN);
  }, [selectedPAN]);

  const handleChangePan = (panNumber) => {
    setSelectedPAN(panNumber);
    console.log("Selected Pan Number: ", panNumber);
    if (!panNumber) {
      message.error("No Pans associated with this workspace");
      return;
    }
    const PanObj = (userDetails?.workspaceInfo?.panlist).find(
      (panobj) => panobj.pan === panNumber
    );
    if (PanObj?.box_folder_id === null) {
      message.error("Contact the Admin to create BoxID for this PAN");
    } else if (PanObj?.status === "PENDING" || PanObj?.status === "ESCALATED") {
      message.error(
        "You do not have invoice access for this PAN, Please Contact the Admin"
      );
      localStorage.setItem("currentBoxId", null);
    } else {
      console.log(
        `"Selected Pan Number: ${panNumber}, boxId: ${PanObj?.box_folder_id}"`
      );
      localStorage.setItem("currentBoxId", PanObj?.box_folder_id);
      message.success(`Showing invoices for ${panNumber}`);
    }
  };

  // Div Block
  return (
    <div className="BoxUI" style={{ height: "100%" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#f6f6f6",
          borderRadius: 4,
        }}
      >
        <small style={{ marginBottom: 2 }}>Select PAN</small>

        <br />
        <Select
          style={{
            width: 200,
          }}
          showSearch
          placeholder="Select a person"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={selectedPAN}
          onChange={handleChangePan}
          options={(panBoxList || []).map((item) => {
            return {
              value: item.pan,
              label: item.pan.toUpperCase(),
              style:
                item.box_folder_id === null
                  ? { backgroundColor: "#faeaeb" }
                  : {},
            };
          })}
        />
      </div>

      {isLoading ? (
        <Loader />
      ) : userDetails?.userInfo?.email?.includes("kgrp.in") ||
        userDetails?.userInfo?.email?.includes("finkraft.ai") ? (
        <iframe
          src="../../BoxWidgetAdmin.html" // Path to your HTML file in the public directory
          width="100%" // Adjust width as needed
          // Adjust height as needed
          title="Embedded HTML"
          style={{ border: "none", height: "100%" }} // Optional styling
        />
      ) : selectedPAN ? (
        <iframe
          src="../../BoxWidget.html" // Path to your HTML file in the public directory
          width="100%" // Adjust width as needed
          // Adjust height as needed
          title="Embedded HTML"
          style={{ border: "none", height: "100%" }} // Optional styling
        />
      ) : (
        <Empty />
      )}
    </div>
  );
}
