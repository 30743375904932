import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Space, message } from "antd";
import { apiPost } from "app/services/apiServices";
import { operationTypeState, userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_CORPORATE_INFO_UPDATE, API_ENDPOINT_UPDATED_USER_INFO } from "app/scenes/Auth/auth.constant";

interface EditCorporateModalProps {
  isVisible: boolean;
  selectedRow: any;
  onCancel: () => void;
}

const EditCorporateModal: React.FC<EditCorporateModalProps> = ({
  isVisible,
  selectedRow,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");

  // useEffect(() => {
  //   if (selectedRow) {
  //     form.setFieldsValue({
  //       TMC: selectedRow.TMC,
  //       website: selectedRow.website,
  //     });
  //   }
  // }, [selectedRow, form]);

   useEffect(() => {
      if (selectedRow) {
        form.setFieldsValue(selectedRow);
      }
    }, [selectedRow, form]);

  const onFinish = async (values: any) => {
    console.log("values are:", values);
    setIsLoading(true);
    const payload = { ...selectedRow, ...values, operationType: "UPDATE",  oldcompany: selectedRow.company };
    const userpayload = {...userDetails?.workspaceInfo}

    try {
      const response = await apiPost(
        `${API_ENDPOINT_CORPORATE_INFO_UPDATE}`,
        payload
      );
      // refetch the userInfo
      const userresponse = await apiPost(
        `${API_ENDPOINT_UPDATED_USER_INFO}`,
        userpayload);
      console.log("New User Info: ", userresponse?.data)

      if (response.status) {

        setUserDetails((prev:any) => ({
          ...prev,
          ...userresponse?.data,
          currentWorkspace: userresponse?.data?.workspaceInfo
        }));

        message.success("TMC details updated successfully");
        onCancel();
      } else if(!response.status) {
        if (response?.error_code === "404") {
          console.log("WORKSPACE ALREADY EXISTS")
          message.error(`An Existing workspace with ${response?.error} Name already exists, Please use a unique Workspace Name`);
        }
      } else {
        console.log("Failed Response: ", response)
      }
    } catch (error) {
      message.error("Failed to update TMC details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Edit TMC Details"
      open={isVisible}
      onCancel={onCancel}
      footer={null}
      cancelButtonProps={{
        style: { color: "#74003B", border: "1px solid #74003B" },
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
     
      >
        <Form.Item
          label="TMC Name"
          name="company"
          rules={[
            { required: true, message: "Please input the TMC name!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input />
        </Form.Item>

        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ background: "#74003B" }}
          >
            Submit
          </Button>
          <Button
            htmlType="button"
            onClick={onCancel}
            style={{ color: "#74003B", border: "1px solid #74003B" }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditCorporateModal;
