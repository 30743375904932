import React, { useState } from "react";
import { Button, Modal, Form, Input, Checkbox, Select, message } from "antd";
import type { FormProps } from "antd";
import { apiPost, apiGet } from "app/services/apiServices";
import { ButtonBaseActions } from "@mui/material";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { useNavigate } from "react-router-dom";
import ShieldIcon from "../../assets/images/shield-tick-safe (1).png";
import { API_ENDPOINT_ADD_WORKSPACE_FOR_PAN} from "app/shared/MasterLayout/masterlayout.constant";

type FieldType = {
  name?: string;
  website?: string;
  pan?: string;
  businessType?: string;
};

const NewEntityModal: React.FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [name,setName] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [businessType, setBusinessType] = useState("CORPORATE");
  const showModal = () => {
    setOpen(true);
  };

  console.log("User Details On Opening New Entity: ", userDetails)

  const isValidPAN = (pan: string) => {
    const panPattern = /^[A-Z0-9]{10}$/i;
    return panPattern.test(pan) || pan === "";
  };

  const handleOk = async (values: FieldType) => {
    setLoading(true);
    setConfirmLoading(true);
    console.log("values:", values);
    let payload = {
      company: values.name,
      website: values.website,
      type: values.businessType,
      entity_type:values.businessType === 'TMC' ? 'INTERMEDIARY' : 'CORPORATE',
      user_id: userDetails?.userInfo?.id,
      selected_workspace_id: userDetails?.workspaceInfo?.id,
      operationType: "CREATE", // Trying to create the workspace
    };
    console.log("payload is:", payload);

    try {
      const response = await apiPost(
        API_ENDPOINT_ADD_WORKSPACE_FOR_PAN,
        payload
      );

      if (response.status) {
        message.success("Added Workspace Successfully")
        const newWorkspace = response.data

        setUserDetails((prev:any) => ({
          ...prev,
          workspaceList:[
            ...prev?.workspaceList,
            {...newWorkspace}
          ]
        }))

        onClose();
      } else if (!response.status) {
          // Handle Non unique company or pan name
          if (response?.error_code === "404") {
            console.log("WORKSPACE ALREADY EXISTS")
            setLoading(false);
            return message.error(`Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`);
          }
      } else {
          setLoading(false);
          return message.error("Could not add Workspaace");
      }
    //   if (response.status) {
    //     message.success("TMC has been added succesfully");
    //     onClose();
    //   } else if (!response.status) {
    //     // Handle Non unique company or pan name
    //     if (response?.error_code === "404") {
    //       console.log("WORKSPACE ALREADY EXISTS")
    //       message.error(`Workspace: ${response?.error} Name already exists, Please use a unique Workspace Name`);
    //     } else if (response?.error_code === "402") {
    //       console.log("PAN ALREADY EXISTS")
    //       message.error(`PAN: ${response?.error} already exists, Please enter Unique PAN`);
    //     }      
    //   } else {
    //     message.error("Could not add TMC");
    //   }
    // console.log("TMC Add triggered")
    } catch (error) {
      message.error("Could not add user");
    } finally {
      setConfirmLoading(false);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    // console.log("Success:", values);
    handleOk(values); // Close modal after successful form submission
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const { Option } = Select;

  return (
    <>
      <Modal
        title={
          <span
            style={{
              fontSize: "24px",
              fontWeight: "normal",
              marginBottom: "24px",
            }}
          >
            Add New Entity
          </span>
        }
        open={visible}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{
          style: { background: "#74003B", borderColor: "#74003B" },
        }}
        cancelButtonProps={{
          style: { color: "#74003B", border: "1px solid #74003B" },
        }}
        footer={false}
        // Footer removed for form buttons
      >

      <Form // On Finish Form calls handleOk
        name = "Add Company Form"
        onFinish = {onFinish}
        onFinishFailed = {onFinishFailed}
        form={form}
        layout="vertical"
        initialValues={{ name: name }}
        fields={[
          {
            name: "name",
            value: name,
          },
          {
            name: "website",
            value: website,
          },
          {
            name: "businessType",
            value: businessType,
          },
        ]}
      >
        {/* <div className="form-header">Enter Company Details</div> */}

        <Form.Item //Compant Name
          label="Company name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            type="text"
            defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter company name"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item //Company Website
          label="Company Website"
          name="website"
          rules={[
            { required: true, message: "Please input your company website!" },
          ]}
          >
          <Input
            type="text"
            defaultValue={website}
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Enter company website"
            style={{ borderColor: "#C3C4CD" }}
          />
        </Form.Item>

        <Form.Item //businessType
          label = "Business Type"
          name = "businessType"
          >
          <Select
            defaultValue={businessType}
            style={{ width: "100%", height: 38 }}
            onChange={(value: any) => setBusinessType(value)}
            options={[
              { value: "CORPORATE", label: "CORPORATE" },
              { value: "CARGO", label: "CARGO" },
              { value: "TMC", label: "TMC" },
              // { value: "PARTNER", label: "PARTNER" },
              { value: "OTA", label: "OTA" },
            ]}
          />  
        </Form.Item>

        <Form.Item // Submit Button
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button 
            type="primary"
            color="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>

        {/* <div className="info-text">
          <img src={ShieldIcon} alt="shield icon" />
          <p>No spams your information is safe with us!</p>
        </div> */}

        {/* <Button
          type="primary"
          className="tax-manager-submit-button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          loading={isLoading}
          style={{
            backgroundColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
            borderColor: isButtonDisabled ? "#A8A8A8" : "#74003B",
          }}
        >
          Next
        </Button> */}
      </Form>
      </Modal>
    </>
  );
};

export default NewEntityModal;
