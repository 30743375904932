export const API_ENDPOINT_ONBOARDING_COMPANY_INFO_UPDATE = `${process.env.REACT_APP_API_URL}/qatar/onboard/company`;
export const API_ENDPOINT_ONBOARDING_INVITE_TAX_MANAGER = `${process.env.REACT_APP_API_URL}/qatar/onboard/tax_manager`;
export const API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_LIST = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/invoice_access_list`;
export const API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/invoice_access_list/update`;
export const API_ENDPOINT_HELP_QUERY_SUBMIT = `${process.env.REACT_APP_API_URL}/qatar/general/helpquery`;
export const API_ENDPOINT_MAIL_STATUS_REQUEST = `${process.env.REACT_APP_API_URL}/qatar/onboarding/escalations`;
export const API_ENDPOINT_MAIL_STATUS_CARD_DATA = `${process.env.REACT_APP_API_URL}/qatar/onboarding/escalations/gist`;
export const API_ENDPOINT_NOTIFY_RESOLVE_MAIL_STATUS = `${process.env.REACT_APP_API_URL}/qatar/onboarding/escalations/resend`;
export const API_ENDPOINT_DASHBOARD_GIST_DATA = `${process.env.REACT_APP_API_URL}/qatar/dashboard/gist`;
export const API_ENDPOINT_INVOICE_REQUEST_GIST_DATA = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/invoice_access_list/gist`;
export const API_ENDPOINT_ONBOARDING_ADD_PAN_TO_WORKSPACE = `${process.env.REACT_APP_API_URL}/qatar/pan`;
export const API_ENDPOINT_EDIT_PAN_IN_WORKSPACE = `${process.env.REACT_APP_API_URL}/qatar/editpan`;
export const API_ENDPOINT_ONBOARDING_SEARCH_ENTITY = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/entity/search`;
export const API_ENDPOINT_ONBOARDING_ADD_TMC_TO_WORKSPACE = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/entity/add/tmc`;
export const API_ENDPOINT_ONBOARD_TMC = `${process.env.REACT_APP_API_URL}/qatar/qatarmeta/entity/invite/tmc_poc`;
export const API_ENDPOINT_ADD_WORKSPACE_FOR_PAN = `${process.env.REACT_APP_API_URL}/qatar/pan/company`;