import { atom } from "recoil";

export const workspaceInfo: any = atom({
  key: "workspaceInfo",
  default: null,
});

export const userInfo: any = atom({
  key: "userInfo",
  default: {},
});

export const onboardInfo: any = atom({
  key: "onboardInfo",
  default: {},
});

export const operationTypeState = atom<string>({
  key: "operationTypeState",
  default: "CREATE",
});

export const boxPanList = atom<[]>({
  key: "boxPanList",
  default: [],
});

export const selectedPanForBox = atom<null>({
  key: "selectedPanForBox",
  default: null,
});

export const hardRefresh = atom<boolean>({
  key: "hardRefresh",
  default: false,
});