import React, { useState } from "react";
import { Button, Modal, Form, Input, Checkbox, message } from "antd";
import type { FormProps } from "antd";
import { apiPost, apiGet } from "app/services/apiServices";
import EntitySearch from "app/shared/EntitySearch";
import { API_ENDPOINT_ONBOARDING_ADD_TMC_TO_WORKSPACE } from "app/shared/MasterLayout/masterlayout.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

type FieldType = {
  username?: string;
  email?: string;
  mobile?: string;
  linkedin?: string;
};

const InviteUserModal: React.FC<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleAddTMC = async () => {
    setLoading(true);
    const payload = {
      workspaceId: userDetails?.workspaceInfo?.id,
      tmcID: selectedEntity,
    };
    try {
      const response = await apiPost(
        API_ENDPOINT_ONBOARDING_ADD_TMC_TO_WORKSPACE,
        payload
      );

      if (response.status) {
        message.success("TMC has been added");
        onClose();
      }
    } catch (error) {
      message.error("Could not add TMC");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        footer={false}
        title={
          <span
            style={{
              fontSize: "24px",
              fontWeight: "normal",
              marginBottom: "24px",
            }}
          >
            Invite TMC
          </span>
        }
        open={visible}
        onCancel={onClose}
        confirmLoading={confirmLoading}
        okButtonProps={{
          style: { background: "#74003B", borderColor: "#74003B" },
        }}
        cancelButtonProps={{
          style: { color: "#74003B", border: "1px solid #74003B" },
        }}
        // Footer removed for form buttons
      >
        <EntitySearch
          onSelect={(selected: string) => setSelectedEntity(selected)}
        />

        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 12 }}
        >
          <Button type="primary" onClick={handleAddTMC}>
            Add
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InviteUserModal;
