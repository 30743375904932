import React, { useState, useMemo, useCallback } from "react";
import { Menu, Dropdown, Input, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FixedSizeList as List } from "react-window"; // Virtualization
import debounce from "lodash/debounce"; // Optimize search input
import Typography from "../Typography";
import { useLocation } from "react-router-dom";

const { Search } = Input;

const workspaceColorMap = [
  "#4555eb",
  "#209f85",
  "#fa694a",
  "#2c3e50",
  "#e74c3c",
  "#8e44ad",
  "#f39c12",
  "#1abc9c",
  "#16a085",
  "#f1c40f",
  "#34495e",
  "#e67e22",
  "#9b59b6",
  "#d35400",
  "#27ae60",
];

const PickWorkspaceColor = (word) => {
  if (!word) return "#cccccc";
  const firstChar = word.charAt(0)?.toLowerCase();
  const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
  const colorIndex = Math.floor(
    alphabetIndex / (26 / workspaceColorMap.length)
  );
  return workspaceColorMap[colorIndex] || "#cccccc";
};

const SearchDropdown = ({ workspaceList, handleSelectWorkspce, userData }) => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    userData?.workspaceInfo || null
  );
  const location = useLocation();

  // Debounced search function to prevent excessive re-renders
  const handleSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
    }, 300),
    []
  );

  // Memoize filtered list to prevent unnecessary re-renders
  const filteredItems = useMemo(() => {
    return workspaceList.filter((item) =>
      item.company?.toLowerCase().includes(searchText?.toLowerCase())
    );
  }, [workspaceList, searchText]);

  const handleClick = (workspace) => {
    setSelectedWorkspace(workspace);
    handleSelectWorkspce(workspace);
    setOpen(false);
  };

  // Virtualized List Rendering (Only renders visible items)
  const Row = ({ index, style }) => {
    const element = filteredItems[index];
    return (
      <div key={element.id} style={style}>
        <Menu.Item
          onClick={() => handleClick(element)}
          style={{
            backgroundColor:
              selectedWorkspace?.id === element.id ? "#f0f0f0" : "transparent",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              size={24}
              style={{
                backgroundColor: PickWorkspaceColor(element.company),
                color: "#fff",
                marginRight: 8,
                width: 24,
              }}
            >
              {element.company.toUpperCase().slice(0, 2)}
            </Avatar>
            <Typography variant="xs" style={{ fontSize: 12 }}>
              {element.company}
            </Typography>
          </div>
        </Menu.Item>
      </div>
    );
  };

  const menu = (
    <Menu style={{ maxHeight: "470px", overflow: "hidden", width: 250 }}>
      <Menu.Item key="search">
        <Input
          placeholder="Search workspaces"
          onChange={(e) => handleSearch(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing on input click
          style={{ height: 32 }}
        />
      </Menu.Item>

      {/* Virtualized List to Render Only Visible Items */}
      <List
        height={300}
        itemCount={filteredItems.length}
        itemSize={40}
        width={250}
      >
        {Row}
      </List>
    </Menu>
  );

  if (location.pathname === "/app") {
    return null;
  }

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      open={open}
      onOpenChange={setOpen}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          size={24}
          style={{
            backgroundColor: PickWorkspaceColor(
              userData?.currentWorkspace?.name ||
                userData?.currentWorkspace?.company
            ),
            color: "#fff",
            marginRight: 8,
          }}
        >
          {(
            userData?.currentWorkspace?.name ||
            userData?.currentWorkspace?.company
          )
            ?.toUpperCase()
            ?.slice(0, 2)}
        </Avatar>
        <Typography variant="caption" wrapped={true}>
          {userData?.currentWorkspace?.name ||
            userData?.currentWorkspace?.company}
        </Typography>
        <DownOutlined style={{ fontSize: "12px", marginLeft: "5px" }} />
      </div>
    </Dropdown>
  );
};

export default SearchDropdown;
