import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Space,
  Select,
  MenuProps,
  Button,
  message,
  Tag,
  Modal,
  Popconfirm,
} from "antd";
import { DownOutlined, EyeFilled } from "@ant-design/icons";

import {
  UserOutlined,
  CheckSquareOutlined,
  FileTextOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Card } from "antd";
import "./InvoiceRequest.scss";
import {
  API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_LIST,
  API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE,
  API_ENDPOINT_MAIL_STATUS_REQUEST,
  API_ENDPOINT_INVOICE_REQUEST_GIST_DATA,
} from "../MasterLayout/masterlayout.constant";
import { apiGet, apiPost } from "app/services/apiServices";
import { debounce } from "lodash";
import CustomAgGrid, { ColumnDefinition } from "../AgGrid";
import { error } from "console";
import { PlaylistAddOutlined } from "@mui/icons-material";

// LicenseManager.setLicenseKey('YOUR_LICENSE_KEY');

const { Search } = Input;

interface BusinessData {
  name: string;
  email: string;
  mobile: string;
  company: string;
  iataCode: string;
  gstin: string;
  requested: string;
}

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: number;
}

const StatCard: React.FC<StatCardProps> = ({ icon, label, value }) => {
  return (
    <Card className="stat-card">
      <div className="stat-inner">
        <div className={`stat-icon `}>{icon}</div>
        <div className="stat-content">
          <h3 className="stat-label">{label}</h3>
          <p className="stat-value">{value}</p>
        </div>
      </div>
    </Card>
  );
};

const BusinessTable: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [backupData, setBackupData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = useRef<any>(null);
  const [searchText, setSearchText] = useState(""); // Controlled input state
  const [isCompanyDetailModalOpen, setIsCompanyDetailModalOpen] =
    useState(false);
  const [isTravelContactModalOpen, setIsTravelContactModalOpen] =
    useState(false);
  const [isTaxManagerModalOpen, setIsTaxManagerModalOpen] = useState(false);
  const [messageState, setMessageState] = useState("");
  const [isEscalateModalOpen, setIsEscalateModalOpen] = useState(false);
  const { TextArea } = Input;
  const [value, setValue] = useState("");
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedWorkspaceInfo, setSelectedWorkspaceInfo] = useState<any>(null);
  const [selectedTaxManager, setSelectedTaxManager] = useState<any>(null);
  const [selectedTravelContact, setSelectedTravelContact] = useState<any>(null);
  const [escalating, setEscalating] = useState(false);
  const [cardData, setCardData] = useState({
    total_users: 0,
    total_pending: 0,
    total_escalated: 0,
    followup_require: 0,
  });
  //  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchTableData();
    fetchCardData();
  }, []);

  const fetchTableData = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_LIST
    );
    if (response.status) {
      setTableData(response.data);
      setBackupData(response.data);
    }
    setLoading(false);
  };

  const fetchCardData = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_INVOICE_REQUEST_GIST_DATA);
    if (response.status) {
      setCardData(response.data);
    }
    setLoading(false);
  };

  const businessTypeItems: MenuProps["items"] = [
    {
      label: <a>Corporate</a>,
      key: "corporate",
    },
    {
      label: <a>Individual</a>,
      key: "individual",
    },
    {
      type: "divider",
      key: "divider",
    },
    {
      label: <a>Other</a>,
      key: "other",
    },
  ];

  const showCompanyDetailModal = (rowData: any) => {
    setSelectedWorkspaceInfo(rowData?.workspace_info);
    setIsCompanyDetailModalOpen(true);
  };

  const showTravelContactModal = (rowData: any) => {
    setSelectedTravelContact(rowData?.travel_contacts?.[0]);
    setIsTravelContactModalOpen(true);
  };

  const showTaxManagerModal = (rowData: any) => {
    setSelectedTaxManager(rowData?.tax_managers?.[0]);
    setIsTaxManagerModalOpen(true);
  };

  const handleSelectChange = (value: any) => {
    setSelectedReason(value);
  };

  const handleOk = () => {
    setIsCompanyDetailModalOpen(false);
    setIsTravelContactModalOpen(false);
    setIsTaxManagerModalOpen(false);
  };

  const handleCancel = () => {
    setIsCompanyDetailModalOpen(false);
    setIsTravelContactModalOpen(false);
    setIsTaxManagerModalOpen(false);
  };

  const CompanyDetailsCellRenderer = (params: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "65px" }}>
        <span style={{ color: "#008000" }}>Completed</span>
        <EyeFilled
          style={{ color: "#1890ff", cursor: "pointer", fontSize: "14px" }}
          onClick={() => showCompanyDetailModal(params.data)}
        />
      </div>
    );
  };

  const TaxManagersCellRenderer = (params: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "65px" }}>
        <span style={{ color: "#008000" }}>Completed</span>
        <EyeFilled
          style={{ color: "#1890ff", cursor: "pointer", fontSize: "14px" }}
          onClick={() => showTaxManagerModal(params.data)}
        />
      </div>
    );
  };

  const TravelContactCellRenderer = (params: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "65px" }}>
        <span style={{ color: "#008000" }}>Completed</span>
        <EyeFilled
          style={{ color: "#1890ff", cursor: "pointer", fontSize: "14px" }}
          onClick={() => showTravelContactModal(params.data)}
        />
      </div>
    );
  };
  const handleMessageChange = (e: any) => {
    setMessageState(e.target.value);
  };

  const stats = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 5.5C12.9283 5.5 13.8185 5.86875 14.4749 6.52513C15.1313 7.1815 15.5 8.07174 15.5 9C15.5 9.92826 15.1313 10.8185 14.4749 11.4749C13.8185 12.1313 12.9283 12.5 12 12.5C11.0717 12.5 10.1815 12.1313 9.52513 11.4749C8.86875 10.8185 8.5 9.92826 8.5 9C8.5 8.07174 8.86875 7.1815 9.52513 6.52513C10.1815 5.86875 11.0717 5.5 12 5.5ZM5 8C5.56 8 6.08 8.15 6.53 8.42C6.38 9.85 6.8 11.27 7.66 12.38C7.16 13.34 6.16 14 5 14C4.20435 14 3.44129 13.6839 2.87868 13.1213C2.31607 12.5587 2 11.7956 2 11C2 10.2044 2.31607 9.44129 2.87868 8.87868C3.44129 8.31607 4.20435 8 5 8ZM19 8C19.7956 8 20.5587 8.31607 21.1213 8.87868C21.6839 9.44129 22 10.2044 22 11C22 11.7956 21.6839 12.5587 21.1213 13.1213C20.5587 13.6839 19.7956 14 19 14C17.84 14 16.84 13.34 16.34 12.38C17.2 11.27 17.62 9.85 17.47 8.42C17.92 8.15 18.44 8 19 8ZM5.5 18.25C5.5 16.18 8.41 14.5 12 14.5C15.59 14.5 18.5 16.18 18.5 18.25V20H5.5V18.25ZM0 20V18.5C0 17.11 1.89 15.94 4.45 15.6C3.86 16.28 3.5 17.22 3.5 18.25V20H0ZM24 20H20.5V18.25C20.5 17.22 20.14 16.28 19.55 15.6C22.11 15.94 24 17.11 24 18.5V20Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Total Users",
      value: cardData.total_users,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 12H22V18H20V12ZM20 20H22V22H20V20ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C14.3 22 16.3 21.2 18 20V10H21.8C20.9 5.4 16.8 2 12 2ZM16.2 16.2L11 13V7H12.5V12.2L17 14.9L16.2 16.2Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Pending Approvals",
      value: cardData.total_pending,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 2C2.89 2 2 2.89 2 4V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H12.41C13.4943 22.6516 14.735 22.9972 16 23C17.8565 23 19.637 22.2625 20.9497 20.9497C22.2625 19.637 23 17.8565 23 16C22.9982 14.4939 22.5108 13.0286 21.61 11.8216C20.7093 10.6146 19.4433 9.7303 18 9.3V8L12 2H4ZM11 3.5L16.5 9H11V3.5ZM16 11C17.3261 11 18.5979 11.5268 19.5355 12.4645C20.4732 13.4021 21 14.6739 21 16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21C14.6739 21 13.4021 20.4732 12.4645 19.5355C11.5268 18.5979 11 17.3261 11 16C11 14.6739 11.5268 13.4021 12.4645 12.4645C13.4021 11.5268 14.6739 11 16 11ZM15 12V17L18.61 19.16L19.36 17.94L16.5 16.25V12H15Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Total Escalated",
      value: cardData.total_escalated,
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM7.7 15.5L7 14.2L11 11.9V7H12.5V12.8L7.7 15.5Z"
            fill="#74003B"
          />
        </svg>
      ),
      label: "Follow-ups Required",
      value: cardData.followup_require,
    },
  ];

  const handleManageRequest = async (requestId: string, status: string) => {
    const response = await apiPost(
      API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE,
      {
        requestId: requestId,
        status: status,
      }
    );
    if (response.status) {
      message.success("Invoice Request accepted");
      console.log("Invoice Request accepted");
    } else {
      message.error("Invoice Request rejected");
      console.log("Invoice Request rejected");
    }
  };

  const showEscalateModal = (rowData: any) => {
    setSelectedRow(rowData);
    setIsEscalateModalOpen(true);
  };

  const handleOkEscalation = () => {
    if (selectedRow) {
      handleEscalate(selectedRow); // Use the stored row data
    }
    setIsEscalateModalOpen(false);
  };

  const handleCancelEscalation = () => {
    setIsEscalateModalOpen(false);
  };

  const handleEscalate = async (rowData: any) => {
    console.log("The selected row is:", rowData);
    setEscalating(true);
    const payload = {
      userId: rowData.requested_user_id,
      email: rowData.requested_user_email,
      name: rowData.requested_user_name,
      pan: rowData?.workspace_info?.pan,
      type: selectedReason,
      message: messageState,
    };
    try {
      const response = await apiPost(API_ENDPOINT_MAIL_STATUS_REQUEST, payload);
      if (response.status) {
        message.success("Escalated successfully.");
        console.log("Escalated successfully.");
        fetchTableData();
        //  return response.data.status;
        setSelectedReason(null);
        setMessageState("");
      } else {
        message.error("Failed to update escalation status.");
        console.log("Escalation rejected.");
      }
    } catch (error) {
      console.error("Error updating escalation status:", error);
      message.error("An error occurred while updating escalation status.");
    }
    setEscalating(false);
  };

  const updateInvoiceStatus = async (requestData: any, status: any) => {
    const response = await apiPost(
      API_ENDPOINT_META_INVOICE_ACCESS_REQUEST_UPDATE,
      {
        requestId: requestData?.invoice_id,
        name: requestData?.name,
        email: requestData?.email,
        status: status,
      }
    );
    if (response.status) {
      message.success("Invoice Request accepted");
      console.log("Invoice Request accepted");
      fetchTableData();
    } else {
      message.error("Invoice Request rejected");
      console.log("Invoice Request rejected");
    }
  };

  const columnDefs: ColumnDefinition[] = [
    {
      field: "requested_user_name",
      headerName: "Name",
      sortable: true,
      filter: true,
    },
    {
      field: "requested_user_email",
      headerName: "Email",
      sortable: true,
      filter: true,
    },
    { field: "requested_user_mobile", headerName: "Mobile", sortable: true },
    {
      field: "companyDetails",
      headerName: "Company Details",
      sortable: true,
      width: 200,
      cellRenderer: CompanyDetailsCellRenderer,
    },
    {
      field: "workspace_info.pan",
      headerName: "Pan",
      sortable: true,
      filter: true,
    },
    {
      field: "taxManager",
      headerName: "Tax Manager",
      sortable: true,
      width: 200,
      cellRenderer: TaxManagersCellRenderer,
    },
    {
      field: "travelContact",
      headerName: "Travel Contact",
      width: 200,
      sortable: true,
      cellRenderer: TravelContactCellRenderer,
    },

    {
      headerName: "Requested",
      field: "requested",
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            {params.data?.invoice_status === "ACCEPTED" ? (
              <Tag color="green">ACCEPTED</Tag>
            ) : params.data?.invoice_status === "REJECTED" ? (
              <Tag color="red">REJECTED</Tag>
            ) : params.data?.invoice_status === "ESCALATED" ? (
              <Tag color="red">ESCALATED</Tag>
            ) : params.data?.invoice_status === "ESCALATION_RESOLVED" ? (
              <Tag color="green">ESCALATION RESOLVED</Tag>
            ) : (
              <div>
                <Popconfirm
                  title="Accept invoice request"
                  description="Are you sure to approve this request?"
                  onConfirm={() => updateInvoiceStatus(params.data, "ACCEPTED")}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#74003B",
                      borderColor: "#74003B",
                    },
                  }}
                >
                  <Tag color="green" style={{ cursor: "pointer" }}>
                    Accept
                  </Tag>
                </Popconfirm>
              </div>
            )}
            {params.data.invoice_status === "ESCALATION_RESOLVED" ? (
              <Tag
                color="green"
                style={{ cursor: "pointer" }}
                onClick={() => updateInvoiceStatus(params.data, "ACCEPTED")}
              >
                Accept
              </Tag>
            ) : null}
            {params.data.invoice_status !== "ACCEPTED" &&
              params.data.invoice_status !== "ESCALATED" &&
              params.data.invoice_status !== "ESCALATION_RESOLVED" && (
                <Tag
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={() => showEscalateModal(params.data)}
                >
                  ESCALATE
                </Tag>
              )}
              {params.data.invoice_status !== "ACCEPTED" &&
              params.data.invoice_status !== "ESCALATED" &&
              params.data.invoice_status !== "ESCALATION_RESOLVED" && (
                <Popconfirm
                  title="Delete invoice request"
                  description="Are you sure to delete this request?"
                  onConfirm={() => updateInvoiceStatus(params.data, "DELETED")}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#74003B",
                      borderColor: "#74003B",
                    },
                  }}
                >
                  <Tag 
                  color="red" 
                  style={{ cursor: "pointer" }}>
                    DELETE
                  </Tag>
                </Popconfirm>
              )}
          </div>
        );
      },
    },
  ];

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit(); // Automatically adjust column widths
    gridRef.current = params.api;
  };

  // Handler to update the quick filter text
  const onQuickFilterTextChange = (e: any) => {
    if (gridRef.current) {
      gridRef.current.setQuickFilter(e.target.value);
    }
  };

  const handleSearch = debounce((text: any) => {
    const filteredData = backupData.filter(
      (row) =>
        row.name.toLowerCase().includes(text.toLowerCase()) ||
        row.email.toLowerCase().includes(text.toLowerCase())
    );
    setTableData(filteredData);
  }, 300);

  // Update searchText and call debounced search function
  const onSearchChange = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    handleSearch(text);
  };

  useEffect(() => {
    // Cleanup the debounce when component unmounts
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  return (
    <div className="business-table">
      <div className="invoice-request-header">
        <div className="stats-section">
          {stats.map((stat, index) => (
            <StatCard
              key={index}
              icon={stat.icon}
              label={stat.label}
              value={stat.value}
              // color={stat.color}
            />
          ))}
        </div>
      </div>
      <div style={{ width: "92%", margin: "0 auto" }}>
        <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
          <CustomAgGrid
            columnDefs={columnDefs}
            rowData={tableData}
            enableFilter={true}
            enableSort={true}
            enablePagination={false}
            enableCheckbox={false}
          />
        </div>
      </div>

      <Modal
        open={isCompanyDetailModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#74003B",
              color: "white",
            }}
          >
            Close
          </Button>,
        ]}
      >
        <div style={{ fontSize: "24px", color: "#000" }}>Company Details</div>
        {selectedWorkspaceInfo ? (
          <>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>
                Company Name
              </div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {selectedWorkspaceInfo.workspace_name}{" "}
              </div>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>
                Company Website
              </div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {selectedWorkspaceInfo.website}
              </div>
            </div>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>PAN Number</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedWorkspaceInfo.pan}
              </div>
            </div>
          </>
        ) : (
          <p>No company details available.</p>
        )}
      </Modal>
      <Modal
        open={isTravelContactModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#74003B",
              color: "white",
            }}
          >
            Close
          </Button>,
        ]}
      >
        <div style={{ fontSize: "24px", color: "#000" }}>Travel Contact</div>
        {selectedTravelContact ? (
          <>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Name</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedTravelContact.user_name}{" "}
              </div>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Email</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {selectedTravelContact.user_email}{" "}
              </div>
            </div>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Mobile</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedTravelContact.user_mobile}
              </div>
            </div>
          </>
        ) : (
          <p>No Travel Contact details available.</p>
        )}
      </Modal>

      <Modal
        open={isTaxManagerModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#74003B",
              color: "white",
            }}
          >
            Close
          </Button>,
        ]}
      >
        <div style={{ fontSize: "24px", color: "#000" }}>Tax Manager</div>
        {selectedTaxManager ? (
          <>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Name</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedTaxManager.user_name}{" "}
              </div>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Email</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedTaxManager.user_email}{" "}
              </div>
            </div>
            <div style={{ padding: "24px 5px" }}>
              <div style={{ fontSize: "12px", color: "#666" }}>Mobile</div>
              <div
                style={{ fontSize: "14px", color: "#555", fontWeight: "600" }}
              >
                {" "}
                {selectedTaxManager.user_mobile}{" "}
              </div>
            </div>
          </>
        ) : (
          <p>No Tax Manager details available.</p>
        )}
      </Modal>

      <Modal
        title="Escalate Onboarding"
        open={isEscalateModalOpen}
        onOk={handleOkEscalation}
        onCancel={handleCancelEscalation}
        footer={[
          <div>
            <Button
              key="cancel"
              onClick={handleCancelEscalation}
              style={{
                width: "30%",
                borderRadius: "8px",
                background: "grey",
                color: "white",
              }}
              loading={escalating}
            >
              Close
            </Button>
            ,
            <Button
              key="ok"
              onClick={handleOkEscalation}
              style={{
                width: "30%",
                borderRadius: "8px",
                background: "#74003B",
                color: "white",
              }}
              loading={escalating}
            >
              Ok
            </Button>
          </div>,
        ]}
      >
        <div style={{ margin: "20px 0px 10px 0px" }}>
          {" "}
          Select a reason to escalate{" "}
        </div>
        <Select
          placeholder="Select a reason to escalate"
          style={{
            width: "100%",
            border: "0.5px solid #bebebe",
            borderRadius: "7px",
          }}
          onChange={handleSelectChange}
          options={[
            { value: "DOCUMENT_MISSING", label: "Document Missing" },
            { value: "INVALID_INFORMATION", label: "Invalid Information" },
          ]}
        />

        <div style={{ margin: "20px 0px 10px 0px" }}>
          {" "}
          Enter the message here:{" "}
        </div>
        <TextArea
          value={messageState}
          onChange={handleMessageChange}
          placeholder="Enter a message"
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{ borderColor: "#bebebe" }}
        />
      </Modal>
    </div>
  );
};

export default BusinessTable;
